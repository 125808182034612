import React, {PropsWithChildren, useRef, useEffect} from "react"

import { useService } from '@SERVICE/dbService'
import { IGlobalState, ITooltip } from "@MODEL/stateModel"

const UiTooltip: React.FC<{children: PropsWithChildren<any>} & ITooltip> = (props : { children: PropsWithChildren<any> }  & ITooltip) => {
    const dbState: IGlobalState = useService()[0]
    const tooltip: React.RefObject<HTMLDivElement> = useRef(null)

    useEffect(() => { return }, [])

    const style = {
        left: dbState.tooltip.posX + 'px',
        top: dbState.tooltip.posY + 'px',
        maxWidth: `calc(100vw - 60px - ${dbState.tooltip.posX}px)`,
        // top: (dbState.tooltip.posY + 'px'),
    }

    return (
        <div ref={tooltip} className="tooltip" style={style}>
            <div className="tooltipContent">
                {props.title && <h3 className="CENTER">{props.title}</h3>}
                {props.subtitle && <p className="CENTER">{props.subtitle}</p>}
                {props.children}
            </div>
        </div>
    )
}

export default UiTooltip