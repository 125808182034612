import React from 'react'
import parse from 'html-react-parser'

import DBService, { imgProps } from '@SERVICE/dbService'

import { EMetaTypes, IMeta } from "@MODEL/uiModel"
import { ITooltip } from '@MODEL/stateModel'
import LogService from './logService'
import { IGlobalTextObj } from '@MODEL/dbModel'

const SharedService = {
    writeContent: (meta: IMeta): JSX.Element | undefined => {
        let content: JSX.Element | undefined = undefined

        switch(meta.type) {
            case EMetaTypes.title:
                content = <h2>{meta.value}</h2>
                break
            case EMetaTypes.subtitle:
                content = <h3>{meta.value}</h3>
                break
            case EMetaTypes.content:
                content = <p>{meta.value}</p>
                break
            case EMetaTypes.image:
                {
                    SharedService.fetchMeta(`/images/${meta.value}`).then((tmpMeta) => {
                        content = (tmpMeta) ? <img className="full" alt="" src={tmpMeta} /> : undefined
                    })
                }
                break
        }

        return content
    },
    fetchMeta: async (src: string): Promise<string | undefined> => {
        try {
            const tmpMeta = await import(`@ASSETS/${src}`)
            return tmpMeta
        } catch {
            LogService.log(`Meta ${src} doesn't exist.`)
            return undefined
        }
    },
    showTooltip: (e: any, status: boolean, options: ITooltip = {}): void => {
        let xtraOptions: ITooltip = {}
        if (status) {
            xtraOptions = {posX: e.pageX, posY: e.pageY}
        }
        DBService.pushState({tooltip: {show: status, ...xtraOptions, ...options}})
    },
    uiText: (texts: number | IGlobalTextObj | IGlobalTextObj[]): any | string => {
        if (Array.isArray(texts)) {
            const tmpObj: any = {}
            texts.forEach(x => {
                if (!x.key) x.key = x.text
                if (typeof(x.key) == 'number') x.key = 'T' + x.key
                tmpObj[x.key] = processText(x)
            })
            // LogService.log(tmpObj)
            return tmpObj
        }
        
        return processText(texts)
    },  

    strToRegex: (value: string): string => {
        if (!value || value === '') return ''
        return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    },
    fixQuotes: (value: string): string => {
        if (!value || value === '') return ''
        return value.replace(/'/g, `''`)
    },

    array_swap: (arr: any[], indexA: number, indexB: number): void => {
        const temp = arr[indexA]
        arr[indexA] = arr[indexB]
        arr[indexB] = temp
    },
    array_move: (arr: any[], indexOld: number, indexNew: number): void => {
        while (indexOld < 0) {
            indexOld += arr.length
        }
        while (indexNew < 0) {
            indexNew += arr.length
        }
        if (indexNew >= arr.length) {
            let k = indexNew - arr.length + 1
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(indexNew, 0, arr.splice(indexOld, 1)[0]);
        // return arr; // for testing purposes
    },

    showPreview: (e: any, type: EMetaTypes, value: string): void => {
        switch (type) {
            case EMetaTypes.content:
                SharedService.showTooltip({posX: 10, posY: 10}, true, { content: <div>{ parse(value) }</div> })
                break
            case EMetaTypes.icon:
                break
            case EMetaTypes.image:
            case EMetaTypes.headerImage:
            case EMetaTypes.backgroundImage:
                SharedService.showTooltip({posX: 10, posY: 10}, true, { content: <img src={ `${imgProps.url}/${imgProps.assets.images}/${value}?${imgProps.suffix.medium}` }></img> })
                break
        }
    },

    hidePreview: (): void => {
        SharedService.showTooltip({}, false)
    },
}

const processText = (txt: IGlobalTextObj | number): string => {
    if (typeof txt === 'number') return DBService.fetchState().globalText['T' + txt]
    
    const baseText = (typeof(txt.text) == 'number') ? DBService.fetchState().globalText['T' + txt.text] : txt.text
    return (!txt.replace) ? 
        baseText
        : txt.replace.reduce((prev, curr) => prev.replace(`[${curr.key}]`, (typeof(curr.text) == 'number') ?  DBService.fetchState().globalText['T' + curr.text] : curr.text), baseText)
}
export default SharedService

