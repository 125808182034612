import React, { PropsWithChildren, useRef } from "react"
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd"

import { IDragItem } from "@MODEL/uiModel"
import _ from "lodash"


const DragItem: React.FC<PropsWithChildren<IDragItem>> = (props: PropsWithChildren<IDragItem>) => {
    const ref = useRef<HTMLDivElement>(null)

    const [{ opacity }, drag] = useDrag({
      type: props.type,
      item: { id: props.id, name: props.name, container: props.container, index: props.index, origIndex: props.index },
      collect: (monitor: any) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    })

    const [, drop] = useDrop({
      accept: ['meta'],
      hover: _.debounce((item: IDragItem, monitor: DropTargetMonitor) => {
          // wanneer hover van een nav item ipv node of node vanuit een andere container
          if (!ref.current || !props.dropProps?.hoverHandler || !item.container || item.container !== props.dragItem?.container) return

          const dragIndex = item.index || 0
          const hoverIndex = props.dragItem?.index || 0

          // Don't replace items with themselves
          if (dragIndex === hoverIndex) return

          // Determine rectangle on screen
          const hoverBoundingRect = ref.current?.getBoundingClientRect()

          // Get vertical middle
          const hoverMiddleY =(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

          // Determine mouse position
          const clientOffset = monitor.getClientOffset()
          if (!clientOffset) return

          // Get pixels to the top
          const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%

          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return

          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

          // Time to actually perform the action
          props.dropProps.hoverHandler(dragIndex, hoverIndex)
          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          item.index = hoverIndex
      }, 10),
    })

    if (props.dropProps && props.dragItem) {
      drag(drop(ref))
    } else {
      drag(ref)
    }

    return (<div ref={ref} className="dropItem NOSELECT" style={{ opacity }} data-label={props.name} title={props.name}>{props.children}</div>)
}

export default DragItem
