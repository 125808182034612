import React, { memo, useState, Fragment } from "react"

import _ from 'lodash'

import DBService from '@SERVICE/dbService'
import SharedService from "@SERVICE/sharedService"

import { TFuncVoid } from "@MODEL/stateModel"
import UiFormItem from "@UI/forms/uiFormItem"
import { EFormItemTypes, IFormItemResponse } from "@MODEL/uiModel"
import UiButton from "@UI/dashboard/button"
import { IFolderItem } from "@MODEL/folderModel"

const FolderItemProps: React.FC<{ item: IFolderItem, updateHandler: TFuncVoid }> = (props: { item: IFolderItem, updateHandler: TFuncVoid }) => {
    const [cloneItem, setCloneItem] = useState<IFolderItem>(_.clone(props.item))

    const saveData = (): void => {
        delete cloneItem.product

        DBService.dbRequest('PATCH', `generic/folder_items/${cloneItem.id}/id`, cloneItem).subscribe((x: IFolderItem[]) => {
            props.updateHandler([{ ...props.item, ...x[0] }])
            DBService.pushState({ modal: { show: false, content: undefined } })
        })
    }

    const updateData = (x: IFormItemResponse) => {
        if (!x.field?.length) return
        
        const tmpCloneItem = _.clone(cloneItem)
        tmpCloneItem[x.field]=x.value

        setCloneItem(tmpCloneItem)
    }

    return (
        <div className="FLEX-COLUMN">
            <div id="modalContent" className="FLEX-COLUMN">
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(8), type: EFormItemTypes.asText, value: cloneItem.id}, showLabel: true}} />
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(45), type: EFormItemTypes.text, value: cloneItem.descr, field: 'descr'}, showLabel: true, blurHandler:updateData }} />
                
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(32), type: EFormItemTypes.switch, value: cloneItem.showContent, field: 'showContent' }, showLabel: true, changeHandler:updateData }} />
                {cloneItem.showContent && <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(33), type: EFormItemTypes.number, value: cloneItem.contentMaxLength, field: 'contentMaxLength'}, showLabel: true, changeHandler:updateData }} />}

                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(34), type: EFormItemTypes.number, value: cloneItem.textSizeMultiplier, field: 'textSizeMultiplier'}, showLabel: true, blurHandler:updateData }} />
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(31), type: EFormItemTypes.switch, value: cloneItem.showAllPrices, field: 'showAllPrices' }, showLabel: true, changeHandler:updateData }} />

                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(63), type: EFormItemTypes.number, value: cloneItem.fixedHeight, field: 'fixedHeight'}, showLabel: true, blurHandler:updateData }} />
                
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(64), type: EFormItemTypes.number, value: cloneItem.marginTop, field: 'marginTop'}, showLabel: true, blurHandler:updateData }} />
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(65), type: EFormItemTypes.number, value: cloneItem.marginRight, field: 'marginRight'}, showLabel: true, blurHandler:updateData }} />
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(66), type: EFormItemTypes.number, value: cloneItem.marginBottom, field: 'marginBottom'}, showLabel: true, blurHandler:updateData }} />
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(67), type: EFormItemTypes.number, value: cloneItem.marginLeft, field: 'marginLeft'}, showLabel: true, blurHandler:updateData }} />

                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(68), type: EFormItemTypes.select, valueOptions: [{id: 'left', label: 'links'}, {id: 'center', label: 'midden'}, {id: 'right', label: 'rechts'}], value: cloneItem.alignHorizontal, field: 'alignHorizontal'}, showLabel: true, changeHandler: updateData}} />
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(69), type: EFormItemTypes.select, valueOptions: [{id: 'top', label: 'boven'}, {id: 'middle', label: 'midden'}, {id: 'bottom', label: 'onder'}], value: cloneItem.alignVertical, field: 'alignVertical'}, showLabel: true, changeHandler: updateData}} />

                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(35), type: EFormItemTypes.switch, value: cloneItem.hasBorder, field: 'hasBorder' }, showLabel: true, changeHandler:updateData }} />
                { cloneItem.hasBorder && <Fragment>
                        <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(37), type: EFormItemTypes.number, value: cloneItem.borderWidth, field: 'borderWidth', min: 0, max: 10}, showLabel: true, blurHandler:updateData }} />
                        <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(36), type: EFormItemTypes.color, value: cloneItem.borderColor, field: 'borderColor', default: '#f0f0f0'}, showLabel: true, changeHandler:updateData }} />
                        <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(38), type: EFormItemTypes.select, valueOptions: [{id: 'solid', label: 'solid'}, {id: 'dashed', label: 'dashed'}, {id: 'dotted', label: 'dotted'}, {id: 'double', label: 'double'}, {id: 'groove', label: 'groove'}, {id: 'ridge', label: 'ridge'}, {id: 'inset', label: 'inset'}, {id: 'outset', label: 'outset'},], value: cloneItem.borderStyle, field: 'borderStyle'}, showLabel: true}} />
                    </Fragment>
                }

                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(39), type: EFormItemTypes.switch, value: cloneItem.hasDropShadow, field: 'hasDropShadow' }, showLabel: true, changeHandler:updateData }} />
                
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(40), type: EFormItemTypes.color, value: cloneItem.backgroundColor, field: 'backgroundColor', default: '#ffffff'}, showLabel: true, changeHandler:updateData }} />
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(41), type: EFormItemTypes.color, value: cloneItem.titleColor, field: 'titleColor', default: '#e32119'}, showLabel: true, changeHandler:updateData }} />
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(42), type: EFormItemTypes.color, value: cloneItem.contentColor, field: 'contentColor', default: '#666666'}, showLabel: true, changeHandler:updateData }} />
            </div>
            <div className="SEPARATOR"></div>
            <UiButton clickHandler={() => setTimeout(() => saveData(), 100)} ><i className="gi-icon prefix" gi-icon="save" />{SharedService.uiText(27)}</UiButton>
        </div>
    )
}

export default memo(FolderItemProps)