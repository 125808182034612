import React, { memo} from "react"

import SharedService from "@SERVICE/sharedService"

import { IInputProps } from "@MODEL/uiModel"


const InputAsTextComponent: React.FC<IInputProps> = (props: IInputProps) => {
    return (
        <div className="asText">
            {props.formItem.value || props.formItem.placeholder || SharedService.uiText(7)}
        </div>
   )
}

export default memo(InputAsTextComponent)