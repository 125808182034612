import React, { memo, useState } from "react"

import _ from 'lodash'

import DBService, { useService } from '@SERVICE/dbService'
import SharedService from "@SERVICE/sharedService"
import DateService from "@SERVICE/dateService"

import { IGlobalState } from "@MODEL/stateModel"
import UiFormItem from "@UI/forms/uiFormItem"
import { EFormComponentTypes, EFormItemTypes, EMutationTypes, IFormItemResponse, IStringBuffer } from "@MODEL/uiModel"
import UiButton from "@UI/dashboard/button"
import { IFolder } from "@MODEL/folderModel"

import MetaPicker from "@COMP/settings/metaPicker"
import { EEndpoints } from "@MODEL/dbModel"
import FolderService from "@SERVICE/folderService"

const URL = window.URL || window.webkitURL
import spinner from "@ASSETS/ui/icons/spinner.svg"

const FolderData: React.FC = () => {
    const dbState: IGlobalState = useService()[0]
    const [dataHtmlUrl, setDataHtmlUrl] = useState<{ tab: Window | null, url: string }>(Object.assign({}))
    const [dataPdfUrl, setDataPdfUrl] = useState<{ tab: Window | null, url: string }>(Object.assign({}))

    const updateFolderData = (x: IFormItemResponse) => {
        if (!(x.value && x.field.length)) return

        DBService.dbRequest('PATCH', `generic/folder_folder/${dbState.currFolder.id}/id`, { [x.field]: x.value } ).subscribe((y: IFolder[]) => {
            // console.log(x)

            // dropdown updaten als de naam gewijzigd werd
            if (x.field === 'descr') {
                const currFolders = dbState.globalFolders
                const currFolder = _.find(currFolders, z => z.id === dbState.currFolder.id)
                if (!currFolder) return

                currFolder.descr = x.value
                DBService.pushState({ globalFolders: currFolders })
            }

            DBService.pushState({ currFolder: y[0] })
        })
    }

    const downloadPdf = (exportOption: 'html' | 'pdf' | 'object') => {
        DBService.pushState({ allowDownload: false })
        
        const folderPdfObj = {
            folderId: dbState.currFolder.id ,
            exportOptions: [exportOption],
            languageId: 1,
            assetPath: "https://www.handyhomeottevaere.be/assets/folders/",
            wsAssetPath: "https://www.handyhomeottevaere.be/"
        }

        DBService.pushState({ modal: { show: true, content: <span><img alt="" src={spinner} /></span>, hideClose: true }})

        DBService.dbRequest('POST', 'folder', folderPdfObj, { endpoint: EEndpoints.fso }).subscribe((x) => {
            DBService.pushState({ modal: { show: false } })

            if (typeof x === 'string') {
                console.error(x)
                DBService.pushState({ modal: { show: true, content: <span>{x}</span>, title: <span>Error</span>, hideClose: true }})
                setTimeout(() => DBService.pushState({ allowDownload: true, modal: { show: false } }), 3000)
                return
            }
            
            switch (exportOption) {
                case 'html': 
                    {
                        if (dataHtmlUrl.url !== '') {
                            URL.revokeObjectURL(dataHtmlUrl.url)
                            dataHtmlUrl.tab?.close()
                        }
                        // const blob = new Blob([x[exportOption] as Buffer], { type: 'text/html' })
                        const blob = new Blob([x[exportOption]], { type: 'text/html' })
                        const tmpDataUrl = URL.createObjectURL(blob)
                        const tab = window.open(tmpDataUrl, exportOption)
                        setDataHtmlUrl({ tab, url: tmpDataUrl})
                    }
                    break
                case 'pdf':
                    {
                        if (dataPdfUrl.url !== '') {
                            URL.revokeObjectURL(dataPdfUrl.url)
                            dataPdfUrl.tab?.close()
                        }
                        const pdfBuffer = new Uint8Array((x.pdf as IStringBuffer).data).buffer as Buffer
                        const blob = new Blob([pdfBuffer], { type: 'application/pdf' })
                        const tmpDataUrl = URL.createObjectURL(blob)
                        const tab = window.open(tmpDataUrl, exportOption)
                        setDataPdfUrl({ tab, url: tmpDataUrl})
                    }
                    break
                    // case 'object':
                    //     break
                }
                DBService.pushState({ allowDownload: true })
        })
    }

    const openModal = (formComponentType: EFormComponentTypes): void => {
        let tmpContent: JSX.Element

        switch (formComponentType) {
            case EFormComponentTypes.metas:
                tmpContent = <MetaPicker itemId={dbState.currFolder.id} itemIdField='folderId' table='folder_folderHasMeta'></MetaPicker>
                break
            default:
                tmpContent = <div>Unknown component</div>
                break
        }
        
        if (!tmpContent) return
        DBService.pushState({ modal: { show: true, content: tmpContent }})
    }

    return (
        <div className="FLEX-COLUMN">
            <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(8), type: EFormItemTypes.asText, value: dbState.currFolder.id}, showLabel: true}} />
            <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(62), type: EFormItemTypes.text, value: dbState.currFolder.descr || 'new folder', field: 'descr'}, showLabel: true, blurHandler: updateFolderData}} />
            <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(6), type: EFormItemTypes.textarea, value: dbState.currFolder.content || '', field: 'content' }, showLabel: true, blurHandler: updateFolderData }} />
            <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(4), type: EFormItemTypes.asText, value: DateService.parseIsoDate(dbState.currFolder.dateAdd)}, showLabel: true}} />
            <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(5), type: EFormItemTypes.asText, value: DateService.parseIsoDate(dbState.currFolder.dateUpdate)}, showLabel: true, blurHandler: updateFolderData }} />
            <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(26), type: EFormItemTypes.select, valueOptions: [{id: 1, label: 'nederlands'}, {id: 2, label: 'frans'}], value: dbState.currFolder.languageId, field: 'languageId'}, showLabel: true, changeHandler: updateFolderData}} />
            <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(12), type: EFormItemTypes.select, valueOptions: dbState.globalFolderTemplates.map(x => ({id: x.id, label: x.descr})), value: dbState.currFolder.templateId, field: 'templateId'}, showLabel: true, changeHandler: updateFolderData}} />
            <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(13), type: EFormItemTypes.text, value: dbState.currFolder.filename || 'new folder', field: 'filename'}, showLabel: true, blurHandler: updateFolderData}} />
            <div className="SEPARATOR"></div>
            <div className="RIGHTFLEX FLEX-ROW SMALLGAP">
                <UiButton clickHandler={() => openModal(EFormComponentTypes.metas)}><i className="gi-icon" gi-icon="list" /></UiButton>
                <UiButton clickHandler={() => FolderService.mutateFolder(EMutationTypes.delete, { id: dbState.currFolder.id })}><i className="gi-icon" gi-icon="delete-bin" /></UiButton>
                
            </div>
            <div className="FLEX-ROW">
                <UiButton clickHandler={() => downloadPdf('html')} disabled={!dbState.allowDownload} xtraClasses={["GROW-MAX"]}><i className="gi-icon prefix" gi-icon="layout" />{SharedService.uiText(59)}</UiButton>
                <div className="FLEX-COLUMN SMALLGAP">
                     <UiButton clickHandler={() => downloadPdf('pdf')} disabled={!dbState.allowDownload} xtraClasses={["GROW-MAX"]}><i className="gi-icon prefix" gi-icon="pdf" />{SharedService.uiText(60)}</UiButton>
                     <a className="noDec" href={dataPdfUrl.url} download={dbState.currFolder.id}>
                        <UiButton
                            clickHandler={() => {
                                return
                            }}
                            disabled={!dataPdfUrl.url?.length}
                            xtraClasses={["GROW-MAX"]}
                        >
                            <i className="gi-icon prefix" gi-icon="download" />{SharedService.uiText(15)}
                        </UiButton>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default memo(FolderData)