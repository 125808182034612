import React, {useEffect, useState} from 'react'

export const useDetectClickedOutside = (ref: React.RefObject<HTMLDivElement>, initState: boolean, listenForEscape = true): {clickedOutside: boolean} => {
    const [clickedOutside, setClickedOutside] = useState(initState)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setClickedOutside(true)
        } else {
            setClickedOutside(false)
        }
    }

    const handleEscape = (event: KeyboardEvent) => {
        if(event.key === 'Escape') {
            setClickedOutside(true)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, true)
        document.addEventListener("keydown", handleEscape, false)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside, true)
            document.removeEventListener("keydown", handleEscape, false)
        }
    }, [ref])  
    
    return {clickedOutside}
}