import InputTextComponent from "./inputTextComponent"
import InputPasswordComponent from "./inputPasswordComponent"
import InputTextareaComponent from "./inputTextareaComponent"
import InputNumberComponent from "./inputNumberComponent"
import InputSelectComponent from "./inputSelectComponent"
import InputAsTextComponent from "./inputAsTextComponent"
import InputSwitchComponent from "./inputSwitchComponent"
import InputRadioBtnComponent from "./inputRadioBtnComponent"
import InputColorComponent from "./inputColorComponent"
import InputEditorComponent from "./inputEditorComponent"



export class InputComponentTypes {
    text = InputTextComponent
    password = InputPasswordComponent
    textarea = InputTextareaComponent
    number = InputNumberComponent
    select = InputSelectComponent
    switch = InputSwitchComponent
    radiobtn = InputRadioBtnComponent
    asText = InputAsTextComponent
    color = InputColorComponent
    editor = InputEditorComponent 
}

