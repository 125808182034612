import React, { Component } from "react"

import { InputComponentTypes } from './formItems/inputTypes.type'

import { IInputProps } from "@MODEL/uiModel"

const inputComponentTypes: InputComponentTypes = new InputComponentTypes()

class UiFormItem extends Component<{formItemProps: IInputProps}> {
    render(): JSX.Element {
        let content: JSX.Element

        const TmpInputType = inputComponentTypes[this.props.formItemProps.formItem.type || '']
        
        if (TmpInputType) {
            content = <TmpInputType 
                {...this.props.formItemProps}
                tabIndex={0}
            />
        } else {
            content = <div className="UI-ERROR">Formitem type not found.</div>
        }

        const tmpClasses = ['GROW-MAX', (this.props.formItemProps.showLabel && this.props.formItemProps.formItem.label) ? 'formitemLabel' : '']

        return (
            <div className={tmpClasses.join(' ')} data-label={this.props.formItemProps.formItem.label} >
                {content}
            </div>
        )
    }
}

export default UiFormItem