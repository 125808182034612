import React, { PropsWithChildren, useRef, Fragment } from "react"

const Background: React.FC<PropsWithChildren<any>> = (props: PropsWithChildren<any> ) => {
    const ref: React.RefObject<HTMLDivElement> = useRef(null)
    
    return (
        <Fragment>
            <div className="background">{props.children}</div>
        </Fragment>
    )
}

export default Background