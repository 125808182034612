import React, { useState, memo } from "react"

// import { debounce } from 'lodash'

import LogService from "@SERVICE/logService"

// import InputTicker from './inputTickerComponent'

import { IInputProps } from "@MODEL/uiModel"

const InputNumberComponent: React.FC<IInputProps> = (props: IInputProps) => {
    const [currValue, setValue] = useState(props.formItem.value as number || 0)
    const [initValue, setInitValue] = useState(props.formItem.value as number || 0)

    const updateQty = (num: number) => {
        if (initValue === currValue) return
        if (props.formItem.max && num > props.formItem.max) num = props.formItem.max
        if (props.formItem.min && num < props.formItem.min) num = props.formItem.min
        setValue(num)

        if (props.changeHandler) {
            props.changeHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: num})
            return
        } 
        if (props.blurHandler) {
            props.blurHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: num})
            return
        } 
        
        LogService.log('geen change- of blurHandler meegegeven')
    }
    // debounce(, 100)

    const resetInit = () => {
        setInitValue(currValue)
    }

    return (
        <div className="">
            <input type="number" 
                placeholder={props.formItem.placeholder}
                min={props.formItem.min}
                max={props.formItem.max}
                step={props.formItem.step}

                onFocus={resetInit}
                onBlur={(e) => updateQty(+e.target.value)}
                onChange={(e) => setValue(+e.target.value)}
                
                disabled={props.formItem.disabled}

                value={currValue}
            />
            {/* <InputTicker 
                changeHandler={updateQty} 
                initValue={currValue}
                min={props.formItem.min}
                max={props.formItem.max}
                step={props.formItem.step}
                xtraClass={['smallBtn']}
            /> */}
        </div>
   )
}

export default memo(InputNumberComponent)