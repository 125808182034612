import React from "react"

import { useService } from '@SERVICE/dbService'
import SharedService from "@SERVICE/sharedService"
import FolderService from "@SERVICE/folderService"

import UiFormItem from "@UI/forms/uiFormItem"
import UiButton from "@UI/dashboard/button"
import FolderData from "./folderData"
import FolderItemList from "./folderItemList"
import ProductPicker from "../product/productPicker"
import ToggleContainer from "@UI/general/toggleContainer"

import { AtLeast, IGlobalState } from "@MODEL/stateModel"
import { EFormItemTypes, EMutationTypes, IFormItemResponse, IInputProps, IValueOptions } from "@MODEL/uiModel"
import { IFolder } from "@MODEL/folderModel"

const FolderProps: React.FC = () => {
    const dbState: IGlobalState = useService()[0]

    const getFolder = (response: AtLeast<IFormItemResponse, 'value'>) => {
        if (response.value.length > 0 && response.value[0] !== dbState.currFolder?.id) {
            FolderService.getFolder(response.value[0])
        }
    }

    const folderSelect: IInputProps = {
        formItem: {
            id: 'folderSelect',
            label: 'folderke',
            type: EFormItemTypes.select,
            valueOptions: dbState.globalFolders.map((x: IFolder) => {return {id: x.id, label: x.descr} as IValueOptions}),
            value: dbState.currFolder?.id || '',
            hasFilter: true,
            isOpenOnLoad: !dbState.currFolder?.id,
        },
        changeHandler: getFolder
    }

    return (
        <div className="uiSidebar FLEX-COLUMN">
            <div className="FLEX-ROW">
                <UiFormItem key={dbState.currFolder?.id} formItemProps={folderSelect}></UiFormItem>
                <UiButton clickHandler={() => FolderService.mutateFolder(EMutationTypes.add)}><i className="gi-icon" gi-icon="file-plus" /></UiButton>
            </div>
            {dbState.currFolder && 
                <div id="properties" className="FLEX-COLUMN RELATIVE FULL-H GROW-MAX">
                    <ToggleContainer initState={true} label={SharedService.uiText(16)}><FolderData key={dbState.currFolder.id} /></ToggleContainer>
                    <ToggleContainer initState={true} label={SharedService.uiText(19)} xtraClasses={['GROW-MAX']}><ProductPicker /></ToggleContainer>
                    <ToggleContainer initState={true} label={SharedService.uiText(17)} xtraClasses={['GROW-MAX']}><FolderItemList /></ToggleContainer>
                </div>
                // xtraClasses={['GROW-MAX', 'FULL-H', 'NOSCROLL']}
            }
        </div>
    )
}

export default FolderProps