import * as _ from 'lodash'
import { forkJoin, Subject, of } from 'rxjs'

import DBService from './dbService'

import { IFolder, IFolderItem, IFolderPage } from '@MODEL/folderModel'
import { EMutationTypes } from '@MODEL/uiModel'
import { AtLeast } from '@MODEL/stateModel'
import { switchMap } from 'rxjs/operators'
import SharedService from './sharedService'

// export const folderSubject: Subject<any> = new Subject()
export const pageSubject: Subject<string> = new Subject()
// export const pageSubject: ReplaySubject<number> = new ReplaySubject(0)

const FolderService = {
    getFolder: (id: string): void => {
        forkJoin([
            DBService.dbRequest('GET', `generic/folder_folder/${id}/id`),
            DBService.dbRequest('GET', `generic/folder_pages`, undefined, {filter: `folderId={${id}} AND active=1`, order: '[order] asc'}),
            DBService.dbRequest('GET', `generic/folder_items`, undefined, {filter: `folderId={${id}} AND active=1`, order: '[order] asc'}),

        ])            
        .subscribe((x: [IFolder[], IFolderPage[], IFolderItem[]] )=> {
            const pageItems = x[2]
            const wachtItems = _.remove(pageItems, x => x.isWaiting)

            DBService.pushState({currFolder: x[0][0], currPages: x[1], currPageId: x[1][0]?.id || 0, currWachtItems: wachtItems, currItems: pageItems })
            
            // pageSubject.next(0)
            // folderSubject.next()
        })
    },
    toggleItemProps: (state?: boolean): void => {
        const tmpState = state || !DBService.fetchState().itemPropsIsVisible
        if (tmpState !== DBService.fetchState().itemPropsIsVisible) DBService.pushState({itemPropsIsVisible: tmpState})
    },
    mutateFolder: (action: EMutationTypes, options?: { id?: string, folderProps?: Partial<IFolder>}): void => {
        switch (action) {
            case EMutationTypes.add: 
                {
                    const newFolder: Partial<IFolder> = {
                        descr: SharedService.uiText(61),
                        templateId: DBService.fetchState().globalFolderTemplates[0].id,
                    }
                    DBService.dbRequest('POST', 'generic/folder_folder', newFolder)
                        .pipe(
                            switchMap((x: IFolder[]) => {
                                const folder = x[0]
                                const newPage: Partial<IFolderPage> = {
                                    folderId: folder.id,
                                    pageTemplateId: DBService.fetchState().globalPageTemplates[0].id,
                                    descr: 'page 1' 
                                }
                                return forkJoin([ of(folder), DBService.dbRequest('POST', 'generic/folder_pages', newPage)])
                            })
                        ).subscribe((x: [IFolder, IFolderPage[]]) => {
                            DBService.pushState({ globalFolders: [ x[0], ...DBService.fetchState().globalFolders] })
                            FolderService.getFolder(x[0].id)
                        })
                }
                break
            case EMutationTypes.delete: 
                if (!options?.id) return

                DBService.dbRequest('GET', 'query', undefined, { query: `EXEC folder_deleteFolder @folderId='${options.id}'` })
                    .subscribe(() => {
                        console.log(`Deleted folder: ${options.id}`)

                        const globalFolders = [...DBService.fetchState().globalFolders]
                        _.remove(globalFolders, y => y.id === options.id)
                        DBService.pushState({ currFolder: undefined, globalFolders: globalFolders })
                    })
                break
        }
    },
    mutatePage: (action: EMutationTypes, options?: { id?: string, moveStep?: number, pageProps?: Partial<IFolderPage>}): void => {
        const tmpPageId = options?.id || DBService.fetchState().currPageId

        switch (action) {
            case EMutationTypes.add: 
                {
                    const currPageNum = options?.pageProps?.order || DBService.fetchState().currPages.length + 1
                    const tmpObj: AtLeast<IFolderPage, 'descr' | 'folderId' | 'pageTemplateId'> = { descr: `page ${currPageNum}`, order: currPageNum, folderId: DBService.fetchState().currFolder.id, pageTemplateId: options?.pageProps?.pageTemplateId || DBService.fetchState().globalPageTemplates[0].id }

                    // bestaande verplaatsen
                    // nieuwe toevoegen op gevragde locatie
                    const newPages = [...DBService.fetchState().currPages]
                    const pageIdsToUpdate = _.filter(newPages, x => x.order >= currPageNum).map((x) => {
                        x.order += 1
                        return `'${x.id}'`
                    })

                    forkJoin([
                        pageIdsToUpdate.length ? DBService.dbRequest('GET', 'query', undefined, { query: `UPDATE folder_pages SET descr='page ' + CAST([order] AS NVARCHAR), [order]=[order]+1 WHERE id IN (${pageIdsToUpdate})` }) : of([]),
                        DBService.dbRequest('POST', 'generic/folder_pages', tmpObj),
                    ]).subscribe((x: [IFolderPage[], IFolderPage[]]) => {
                        if (!x[1][0]) return

                        newPages.push(x[1][0])

                        DBService.pushState({ currPageId: x[1][0].id, currPages: _.orderBy(newPages, 'order', 'asc') })
                        // FolderService.toggleItemProps(true)
                    })
                }
                break
            case EMutationTypes.edit:
                // FolderService.toggleItemProps(true)
                break
            case EMutationTypes.delete: 
                {
                    if (!options?.pageProps?.order) return

                    const currPageNum = options?.pageProps?.order
                    const newPages = [...DBService.fetchState().currPages]
                    const pageIdsToUpdate = _.filter(newPages, x => x.order > currPageNum).map((x) => {
                        x.order -= 1
                        return `'${x.id}'`
                    })

                    forkJoin([
                        pageIdsToUpdate.length ? DBService.dbRequest('GET', 'query', undefined, { query: `UPDATE folder_pages SET descr='page ' + CAST([order] AS NVARCHAR), [order]=[order]-1 WHERE id IN (${pageIdsToUpdate})` }) : of([]),
                        DBService.dbRequest('GET', 'query', undefined, { query: `EXEC folder_deletePage @pageId='${tmpPageId}'` })
                    ])
                    .subscribe(() => {
                        console.log(`Deleted page: ${options.id}`)

                        _.remove(newPages, y => y.id === options.id)
                        DBService.pushState({ currPages: _.orderBy(newPages, 'order', 'asc') })
                    })
                }
                break
            case EMutationTypes.move:
                {
                    if (!options?.moveStep) return
                    
                    const newPages = [...DBService.fetchState().currPages]
                    const pageIndex = _.findIndex(newPages, x => x.id === options.id)
                    if (pageIndex === -1) return
                    
                    let fromIndex: number
                    let toIndex: number
                    let directionStep: number

                    if (options.moveStep > 0) {
                        directionStep = -1
                        fromIndex = pageIndex
                        toIndex = pageIndex + options.moveStep
                    } else {
                        directionStep = 1
                        fromIndex = pageIndex + options.moveStep
                        toIndex = pageIndex
                    }

                    toIndex = (toIndex <= 0) ? 0 : (toIndex > DBService.fetchState().currPages.length -1) ? DBService.fetchState().currPages.length -1 : toIndex
                    
                    
                    const pageIdsToUpdate: string[] = []
                    for (let x=fromIndex; x <= toIndex; x++) {
                        newPages[x].order += directionStep
                        pageIdsToUpdate.push(`'${newPages[x].id}'`)
                    }
                    
                    if (!pageIdsToUpdate.length) return
                    
                    let movedPage: IFolderPage
                    if (options.moveStep > 0) {
                        movedPage = newPages[fromIndex]
                        movedPage.order = toIndex
                    } else {
                        movedPage = newPages[toIndex]
                        movedPage.order = fromIndex
                    }


                    DBService.dbRequest('GET', 'query', undefined, { query: `UPDATE folder_pages SET descr='page ' + CAST([order] AS NVARCHAR), [order]=[order]+${directionStep} WHERE id IN (${pageIdsToUpdate})` })
                        .pipe(
                            switchMap(() => DBService.dbRequest('GET', 'query', undefined, { query: `UPDATE folder_pages SET descr='page ${movedPage.order}', [order]=${movedPage.order} WHERE id='${movedPage.id}'` }))
                        )
                        .subscribe(() => {
                            console.log(`Moved page: ${options.id}`)

                            DBService.pushState({ currPages: _.orderBy(newPages, 'order', 'asc') })
                        })
                }
                break
        }
    },
    mutateItem: (action: EMutationTypes, options?: { id?: string, itemProps?: Partial<IFolderItem>}): void => {
        switch (action) {
            case EMutationTypes.delete: 
                if (!options?.id) return

                DBService.dbRequest('GET', 'query', undefined, { query: `EXEC folder_deleteItem @itemId='${options.id}'` })
                    .subscribe(() => {
                        console.log(`Deleted item: ${options.id}`)
                    })
                break
        }            
    },
}

export default FolderService

