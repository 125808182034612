import { useState, useEffect, Dispatch } from 'react'
import Axios, { AxiosRequestConfig, Method, AxiosPromise, AxiosRequestHeaders } from 'axios'

import SharedService from '@SERVICE/sharedService'
import LogService from '@SERVICE/logService'

import { from, Observable, of } from 'rxjs'
import { tap, map, take, retry} from 'rxjs/operators'
import _ from 'lodash'

import { IGlobalState } from '@MODEL/stateModel'
import { EEndpoints, IRequestOptions, TCustomHeaders } from '@MODEL/dbModel'

let globalState: IGlobalState = Object.assign({})
const listeners: Dispatch<any>[]  = []
let actions: any = Object.assign({})

const restUrl = process.env.REACT_APP_DBSERVER
const fsoUrl = process.env.REACT_APP_FSOSERVER
export const restCredentials = { key: process.env.REACT_APP_DBKEY}
export const imgProps = { 
    url: process.env.REACT_APP_IMG_URL, 
    suffix: {
        small: process.env.REACT_APP_IMG_SUFFIX?.replaceAll('%1', '100'),
        medium: process.env.REACT_APP_IMG_SUFFIX?.replaceAll('%1', '300'),
    },
    assets: { 
        images: `${process.env.REACT_APP_ASSET_FOLDER}/images`, 
        icons: `${process.env.REACT_APP_ASSET_FOLDER}/icons` 
    }
}

let authToken = ''

export const useService = (): any[] => {
    const setState = useState(globalState)[1]
    const dispatch = (actionID: string, params: any) => {
        const newState = actions[actionID](globalState, params)
        DBService.pushState(newState)
    }

    useEffect(() => {
        listeners.push(setState)

        return () => {
            _.remove(listeners, x => x === setState)
        }
    }, [setState])

    return [globalState, dispatch]
}

const DBService = {
    // SERVICE FUNCS
    initService: (initActions: any, initState: Partial<IGlobalState>): void => {
        if (initState) globalState = {...globalState, ...initState}
        if (initActions) actions = {...actions, ...initActions}
    },
    
    pushState: (newState: Partial<IGlobalState>): void => {
        globalState = {...globalState, ...newState}
        // LogService.log(globalState)

        _.forEach(listeners, (x: any) => x ? x(globalState) : null)
    },

    fetchState: (): IGlobalState => {
        return {...globalState}
    },

    setToken: (token: string): void => {
        authToken = token
    },    
   
    // CUSTOM FUNCS
    dbRequest: (command: Method, table: string, object?: any, options?: IRequestOptions): Observable<any> => {
        // console.log(table, object)
        // const errorTxt = command + ' item in ' + table + ' with ' + JSON.stringify(object)
        //     + ' (filter: ' + filter + ' / top: ' + top + ' / order: ' + order + ' / query: ' + query + ')'

        
        let qryHeaders: TCustomHeaders = {'content-type': 'application/json', 'authorization': `Bearer ${authToken}`, 'x-auth-token': authToken, ...options?.headers }

        if (options?.filter) qryHeaders = {...qryHeaders, 'x-filter': DBService.fixFilter(options?.filter || '')}
        // if (options?.filter) qryHeaders = {...qryHeaders, 'x-filter': options?.filter || ''}
        if (options?.top)    qryHeaders = {...qryHeaders, 'x-top': options?.top}
        if (options?.order)  qryHeaders = {...qryHeaders, 'x-order': options?.order}
        if (options?.query)  qryHeaders = {...qryHeaders, 'x-qry': (options?.query || '').replace(/(?:\r\n|\r|\n)/g, ' ')}
        
        console.log('callDB', command, table, qryHeaders)

        const requestConfig: AxiosRequestConfig = {
            method: command,
            baseURL: options?.endpoint === EEndpoints.fso ? fsoUrl : restUrl,
            url: table,
            headers: qryHeaders as unknown as AxiosRequestHeaders,
            data: object,
            responseType: 'json',
        }

        try {
         return from<AxiosPromise>(Axios(requestConfig))
                .pipe(
                    retry(2),
                    take(1),
                    map(r => r.data),
                    tap((r: any) => {
                        // LogService.log(r)
                        if (!r.success) {
                            // Wanneer geen token, default user inloggen
                            // this.uiDesktopService.login()
                            console.error('DBRequest: ' + r.message)
                            // fout loggen
                        
                            return of(r.message)
                        } else {
                            // loggen indien geen GET (insert, update, patch, delete, ...)
                        }
                    }),
                    map(r => (r.message) ? r : r.data),
                )
        }
        catch(error) { 
            const errorMsg = 'nog te bepalen'
            return of(errorMsg)
        } 
    },
    fixFilter: (value: string): string => {
        if (!value || value === '') return ''
        
        value = SharedService.fixQuotes(value).replace(/(?:}|{)/g, "'")
        return value
    },
}

export default DBService