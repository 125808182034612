import ProductDetail from './productDetail' 
import GenericDetail from './genericDetail'
import NewItemDetail from './newItemDetail'

export class TemplateComponentTypes {
    product = ProductDetail
    generic = GenericDetail
    newItem = NewItemDetail
}

export type TTemplateComponentTypes = keyof TemplateComponentTypes
export type TDragTypes = keyof TemplateComponentTypes | 'meta'