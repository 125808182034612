import { Dropzone, FileItem, FullScreenPreview } from '@dropzone-ui/react'
import { EMetaTypes, IFileToUpload, IMeta, IUploadedFile } from '@MODEL/uiModel'
import DBService from '@SERVICE/dbService'
import React, { useState } from 'react'
import { forkJoin, Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'

// class Layout extends Component {
const FileUpload: React.FC = () => {
	const [files, setFiles] = useState<IFileToUpload[]>([])
	const [imageSrc, setImageSrc] = useState(undefined)

	const updateFiles = (incommingFiles): void => {
		console.log("incomming files", incommingFiles)
		setFiles(incommingFiles)
	}

	const onDelete = (id): void => {
		setFiles(files.filter((x) => x.id !== id))
	}

	const handleSee = (imageSource): void => {
		setImageSrc(imageSource)
	}

	const handleClean = (files): void => {
		console.log("list cleaned", files)
	}

	const upload = (): void => {
		// Multipart data opmaken
		const dataImage = new FormData() 
		dataImage.append('path', 'handyhomeottevaere_be/httpdocs/assets/folders/images')
		dataImage.append('server', 'iis1')
		
		const dataIcon = new FormData() 
		dataIcon.append('path', 'handyhomeottevaere_be/httpdocs/assets/folders/icons')
		dataIcon.append('server', 'iis1')

		const filenames: string[] = []
		files.forEach((x) => {
			filenames.push(`{${x.file.name}}`)

			if (x.file.type === 'image/svg+xml') {
				dataIcon.append('file', x.file)
				return
			}

			dataImage.append('file', x.file)
		})

		forkJoin([
			DBService.dbRequest('POST', 'fso/upload', dataImage, { headers: { 'Content-Type': 'multipart/form-data' } }),
			DBService.dbRequest('POST', 'fso/upload', dataIcon, { headers: { 'Content-Type': 'multipart/form-data' } }),
			DBService.dbRequest('GET', 'generic/folder_meta', undefined, { filter: `value IN (${filenames})` }),
		])
			.pipe(
				switchMap((x: [IUploadedFile, IUploadedFile, IMeta[]]) => {
					const [images, icons, existingFiles] = x

					const obsToProcess: Observable<IMeta[]>[] = []
					
					images.files?.forEach((y) => {
						if (existingFiles.findIndex(z => z.value === y.filename) > -1) return
						
						const tmpObj: Partial<IMeta> = { type: EMetaTypes.image, value: y.filename }
						obsToProcess.push(DBService.dbRequest('POST', 'generic/folder_meta', tmpObj))
					})
					
					icons.files?.forEach((y) => {
						if (existingFiles.findIndex(z => z.value === y.filename) > -1) return

						const tmpObj: Partial<IMeta> = { type: EMetaTypes.icon, value: y.filename }
						obsToProcess.push(DBService.dbRequest('POST', 'generic/folder_meta', tmpObj))
					})

					if (!obsToProcess.length) return of([])

					return forkJoin(obsToProcess)
				})
			)
			.subscribe((x: IMeta[][]) => {
				console.log('uploaded', x)
				setFiles([])
			})
	}

	
    return (
		<div className="FULL FLEX-COLUMN">
			<Dropzone
				style={{ minWidth: "550px" }}
				//view={"list"}
				onChange={updateFiles}
				minHeight="190px"
				onClean={handleClean}
				value={files}
				maxFiles={25}
				//header={false}
				// footer={false}
				maxFileSize={2998000}
				label="Drag'n drop files here or click to browse"
				accept=".png, image/*"
				// uploadingMessage={"Uploading..."}
				// url="http://localhost:4201/fso/upload"
				// url="http://ec2-99-99-9-9.compute-1.amazonaws.com:2800/upload-my-file"
				//of course this url doens´t work, is only to make upload button visible
				//uploadOnDrop
				// clickable={false}
				// fakeUploading
				>
				{files.map((file) => (
					<FileItem
						{...file}
						key={file.id}
						onDelete={onDelete}
						onSee={handleSee}

						preview
						hd
						// info
					/>
				))}
				<FullScreenPreview
					imgSource={imageSrc}
					openImage={imageSrc}
					onClose={(e) => handleSee(undefined)}
				/>
			</Dropzone>

			<button onClick={upload} disabled={!files.length}>Upload</button>
		</div>
    )
}
 
export default FileUpload