import React, { useEffect, useRef, useState } from "react"
import _ from "lodash"

import DBService from '@SERVICE/dbService'
import SharedService from "@SERVICE/sharedService"

import UiFormItem from "@UI/forms/uiFormItem"
import ProductDetail from "@COMP/product/productDetail"

import { EFormItemTypes, IFormItemResponse } from "@MODEL/uiModel"
import { IProduct } from "@MODEL/folderModel"


let ignoreWords: string[] = []
let replaceWords: string[][] = []

const ProductPicker: React.FC = () => {
    const [currProducts, setProducts] = useState<IProduct[]>([])

    const ref: React.RefObject<HTMLDivElement> = useRef(null)
    // let height = 200

    useEffect(() => {
        DBService.dbRequest('GET', 'generic/translations_lang', undefined, { filter: `lang={nl} AND id IN (227, 233)`, order: 'id asc' }).subscribe((x: { id: number; title: string}[]) => {
            ignoreWords = x[0].title.split(',').map(y => y.trim())
            replaceWords = x[1].title.split(',').map(y => y.split(':'))
        })
    }, [])
    
    if (ref.current) {
        // height = window.innerHeight - ref.current.offsetTop - 80
        // style={{height: `${height}px`}}
    }

    const filterProducts = _.debounce((e: IFormItemResponse) => {
        if (e.value === '') return
        
        let searchWord: string = e.value

        ignoreWords.forEach(x => {
            searchWord = SharedService.fixQuotes(searchWord.replace(String.raw`/${SharedService.strToRegex(x)}/g`, ''))
        })
        replaceWords.forEach(x => {
            if (x.length !== 2) return

            const subWords = x[0].split('|').map(y => y.trim())
            subWords.forEach(y => {
                searchWord = SharedService.fixQuotes(searchWord.replace(String.raw`/${SharedService.strToRegex(y)}/g`, x[1]))
            })
        })

        const searchWords = searchWord.split(',')
        let searchAnd = ''

        searchWords.forEach((x, i) => {
            const searchWordsInner = x.split(' ')
            const isMultipleWords = searchWordsInner.length > 1

            const searchWNear = `"${x.replace(/\s/g, '*","')}*"`
            const searchW = `"${x}"`

            if (i > 0) searchAnd += ' OR '
            if (isMultipleWords) {
                searchAnd += `ISABOUT(${searchW} weight (0.8), NEAR(${searchWNear}) weight (0.4))`;
            } else {
                searchAnd += `ISABOUT(FORMSOF(INFLECTIONAL,${searchW}) weight (.8), ${searchW} weight (0.8), ${searchWNear} weight (0.4))`
            }
        })

        const tmpQuery = `SELECT TOP 50 FP.*, tmp.rank
            FROM folder_prods FP
            INNER JOIN CONTAINSTABLE(Search_Content, 
                (content_nl), '${searchAnd}', LANGUAGE 'Dutch') AS tmp  
            ON FP.id = tmp.[KEY]
            WHERE rank > 0 AND FP.languageId=1
            ORDER BY FP.title`

        DBService.dbRequest('GET', 'query', undefined, { query: tmpQuery }).subscribe((x: IProduct[]) => {
            setProducts(x)
        })
    }, 200)

    return (
        <div ref={ref} className="FLEX-COLUMN FULL-H NOSCROLL GROW-MAX" >
            <div>
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(3), type: EFormItemTypes.text, value: ''}, showLabel: true, changeHandler: filterProducts }} />
            </div>
            <div className="FLEX-COLUMN GROW-MAX uiDropContainer">
                {
                    currProducts.map(x => <ProductDetail key={x.id} product={x} container='' />)
                }
            </div>
        </div>
    )
}

export default ProductPicker