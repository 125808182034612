import React, { useState, useEffect, memo, useRef} from "react"
import JoditEditor from "jodit-react"

import { IInputProps } from "@MODEL/uiModel"
import LogService from "@SERVICE/logService"


const InputEditorComponent: React.FC<IInputProps> = (props: IInputProps) => {
    const [currValue, setValue] = useState(props.formItem.value as string || '')
    const [initValue, setInitValue] = useState(props.formItem.value as string || '')
    const editor = useRef(null)

    useEffect(() => {
        return
        // console.log(props)
    }, [currValue])

    const updateValue = (e: string) => {
        setValue(e)

        if (props.blurHandler) {
            props.blurHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: e})
        } else {
            LogService.log('geen blurhandler meegegeven')
        }
    }

    const resetInit = () => {
        setInitValue(currValue)
    }

    // all options from https://xdsoft.net/jodit/doc/
    const config: any = {
        width: 550,
        height: 300,
		readonly: false,

	}

    return (
        <div className="">
             <JoditEditor
                ref={editor}
                value={currValue}
                config={config}
                // tabIndex=1 // tabIndex of textarea
                onBlur={newContent => updateValue(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => { return }}
            />
        </div>
   )
}

export default memo(InputEditorComponent)