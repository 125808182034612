import React, { useState, useEffect, useRef, memo} from "react"

import * as _ from 'lodash'

import { IInputProps } from "@MODEL/uiModel"
import LogService from "@SERVICE/logService"


const InputRadioBtnComponent: React.FC<IInputProps> = (props: IInputProps) => {
    const [currValue, setValue] = useState((props.formItem.value !== '') ? Array.isArray(props.formItem.value) ? props.formItem.value : [props.formItem.value] : [])
    const [currValueOptions] = useState(props.formItem.valueOptions || [])

    const ref: React.RefObject<HTMLDivElement> = useRef(null)

    useEffect(() => {
        if (props.replyValueOnLoad && props.changeHandler) {
            props.changeHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: currValue})
        }
    }, [])

    const updateValue = async (e: string | number) => {
        let tmpValues = [...currValue]

        if (props.formItem.isMultiSelect) {
            const tmpDelValues = _.remove(tmpValues, x => x === e)
            if (tmpDelValues.length === 0) tmpValues.push(e)
        } else {
            tmpValues = [e]
        }
        setValue(tmpValues)
        if (props.changeHandler) {
            props.changeHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: tmpValues})
        } else {
            LogService.log('Geen changeHandler meegegeven.')
        }
    }

    return (
        <div ref={ref} className={['RELATIVE radiobtn', props.formItem.xtraFormitemClass].join(' ')}>
            {currValueOptions.map(x => {
                const isActive = _.includes(currValue, x.id)
                const tmpClasses: string[] = [(isActive) ? 'active' : '', (x.isDisabled) ? 'disabled' : '']

                return (
                    <button
                        key={x.id}
                        onClick={() => {if (!x.isDisabled) updateValue(x.id)}}

                        className={tmpClasses.join(' ')}
                        >
                        {x.label}
                    </button>
                )
            })
            }
        </div>
   )
}

export default memo(InputRadioBtnComponent)