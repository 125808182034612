import React, { useRef } from "react"

import PageWrapper from "@COMP/pageWrapper"
import FolderProps from "@COMP/settings/folderProps"
// import ItemProps from "./settings/itemProps"

const SoftContainer: React.FC = () => {
    const ref: React.RefObject<HTMLDivElement> = useRef(null)

    return (
        <div ref={ref} className="softContainer RELATIVE">
            <FolderProps></FolderProps>
            <PageWrapper></PageWrapper>
            {/* <ItemProps></ItemProps> */}
        </div>
    )
}

export default SoftContainer