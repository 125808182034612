import React, { useEffect, memo, useState } from "react"

import * as _ from 'lodash'

import DBService from '@SERVICE/dbService'
import SharedService from "@SERVICE/sharedService"

import { TFuncVoid } from "@MODEL/stateModel"
import { IDragItem, IMeta, IMetaConnection, TAllMetaConnections } from "@MODEL/uiModel"
import UiButton from "@UI/dashboard/button"
import { useDrop } from "react-dnd"
import { forkJoin, Observable, of } from "rxjs"
import DragContainer from "./dragContainer"
import { map, switchMap } from "rxjs/operators"
import DragItem from "./dragItem"
import MetaProps from "@COMP/settings/metaProps"
import FileUpload from "@UI/general/fileupload"

const MetaPicker: React.FC<{ itemId: string, itemIdField: string, table:string, updateHandler?: TFuncVoid }> = (props: { itemId: string, itemIdField: string, table:string, updateHandler?: TFuncVoid }) => {
    const [currItems, setItems] = useState<TAllMetaConnections[]>([])
    const [currTab, setTab] = useState<number>(0)

    const tabs = [{ label: `Link meta's` }, { label: `Manage meta's` }, { label: 'Manage images' }]

    const container = 'meta'


    const [, drop] = useDrop({
        accept: ['meta'],
        drop(item: IDragItem, monitor) {
          const dropResult: {dropEffect: string, isProcessed?: boolean} | null = monitor.getDropResult()
          
          if (dropResult?.isProcessed) return

          // Drop new
          if (item.container !== container) {
            // Als hij al bestaat niets doen
            if (_.findIndex(currItems, x => x.metaId === item.id) > -1) return

            const tmpObj: Partial<TAllMetaConnections> = {
                metaId: item.id,
                [props.itemIdField]: props.itemId,
                // order: item.order
                active: true,
            }
            
            fetchItems(DBService.dbRequest('POST', `generic/${props.table}`, tmpObj))
                .subscribe((x: TAllMetaConnections[]) => {
                    setItems([...currItems, ...x])
                })
        
          } else {
            // console.log('item', item)
            if ((item.index === undefined || item.origIndex === undefined)) return

            const fromIndex = item.index < item.origIndex ? item.index : item.origIndex
            
            // console.log('door', currItems)

            const obsToProcess: Observable<TAllMetaConnections>[] = []
            for (let x = fromIndex; x < currItems.length; x++) {
                obsToProcess.push(DBService.dbRequest('PATCH', `generic/${props.table}`, { order: x }, { filter: `${props.itemIdField}={${props.itemId}} AND metaId={${currItems[x].metaId}}` }))
            }
            forkJoin(obsToProcess).subscribe(() => {
                setItems([...currItems])
            })
          }
    
          return {isProcessed: true}
        }
      })

      const deleteConnection = (metaId: string): void => {
        DBService.dbRequest('DELETE', `generic/${props.table}`, undefined, { filter: `${props.itemIdField}={${props.itemId}} AND metaId={${metaId}}` })
            .subscribe((x: IMetaConnection[]) => {
                const newItems = [...currItems]
                x.forEach(y => {
                    _.remove(newItems, z => z.metaId === y.metaId)
                })
                setItems(newItems)
            })
      }

    const closeModal = (): void => {
        DBService.pushState({ modal: { show: false, content: undefined } })
        if (props.updateHandler) props.updateHandler()
    }

    const fetchItems = (obs: Observable<TAllMetaConnections[]>): Observable<TAllMetaConnections[]> => {
        return obs.pipe(
            switchMap((x:TAllMetaConnections[]) => {
                const metaIds: string[] = x.map((y) => `{${y.metaId}}`)
                return forkJoin([ of(x), DBService.dbRequest('GET', 'generic/folder_meta', undefined, { filter: `id in (${metaIds})` }) ])
            }),
            map((x:[TAllMetaConnections[], IMeta[]]) => {
                 x[0].forEach(y => {
                    y.meta = _.find(x[1], z => z.id === y.metaId)
                })
                return x[0]
            })
        )
    }

    const reorderItems = (dragIndex: number, hoverIndex: number) => {
        SharedService.array_move(currItems, dragIndex, hoverIndex)
    }

    const switchTab = (index: number): void => {
        setTab(index)
    }

    useEffect(() => {
        fetchItems(DBService.dbRequest('GET', `generic/${props.table}`, undefined, { filter: `${props.itemIdField}={${props.itemId}}`, order: '[order] asc' }))
            .subscribe((x:TAllMetaConnections[]) => {
                setItems(x)
            })
    }, [])

    return (
        <div className="FLEX-COLUMN">
            <div className="FLEX-ROW tabContainer">
                {
                    tabs.map((x, i) =>  {
                        const classes = ['tab']
                        if (currTab === i) classes.push('active')
                        return <div key={`tab-${i}`} className={classes.join(' ')} onClick={() => switchTab(i)} >{ x.label }</div>
                    })
                }
            </div>
            
            { currTab === 0 && 
                <div id="metaPicker" className="FLEX-ROW RELATIVE">
                    <div className="sidebar">
                        <DragContainer table='folder_meta'></DragContainer>
                    </div>
                    <div className="dropbar GROW-MAX">
                        <div ref={drop} className="FLEX-COLUMN SMALLGAP FULL-H NOSCROLL dropZone uiDropContainer" >
                            {
                                currItems.map((x, i) => 
                                    <DragItem key={x.metaId} 
                                        container='meta' 
                                        index={i}
                                        id={x.metaId} 
                                        type='meta' 
                                        dragItem={{ index: i, id: x.metaId, container: 'meta' }}
                                        dropProps={{ hoverHandler: reorderItems }}
                                    >
                                        <div className=" FLEX-ROW NOSELECT RELATIVE" data-id={x.metaId} title={x.meta?.value}>
                                            <span className="ELLIPSIS GROW-MAX">{x.meta?.value} </span>
                                            <i onClick={() => deleteConnection(x.metaId)} className="gi-icon suffix btn" gi-icon="delete" />
                                        </div>
                                    </DragItem>
                                )
                            }
                        </div>
                        
                    </div>
                </div>
            }

            { currTab === 1 && 
                <div id="metaPicker" className="FLEX-ROW RELATIVE">
                    <MetaProps></MetaProps>
                </div>
            }

            { currTab === 2 && 
                <div id="imagePicker" className="FLEX-ROW RELATIVE">
                    <FileUpload></FileUpload>
                </div>
            }

            <div className="SEPARATOR"></div>
            <div className="LEFTFLEX CENTERFLEX-V">
                <UiButton clickHandler={closeModal} ><i className="gi-icon prefix" gi-icon="check" />{SharedService.uiText(46)}</UiButton>
            </div>
        </div>
    )
}

export default memo(MetaPicker)