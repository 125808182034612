import * as _ from 'lodash'
import * as datefns from 'date-fns'

import LogService from './logService'
import SharedService from './sharedService'

const DateService = {
    convertTime: (value: any, convertorParam = ''): any => {
        const formatting = (convertorParam && convertorParam !== '') ? convertorParam : SharedService.uiText(11)
        const m = (value === '') ? new Date() : value
        return datefns.lightFormat(m, formatting)
    },
    convertDate: (value: any, convertorParam = '', returnOrigVal = true): any => {
        const formatting = (convertorParam && convertorParam !== '') ? convertorParam : SharedService.uiText(9)

        const m = (value === '') ? new Date() : (value instanceof Date) ? value : datefns.parse(value, formatting, new Date())
        if (!datefns.isValid(m)) {
            return (returnOrigVal) ? value : null
        }
        return datefns.lightFormat(m, formatting)    // value
    },
    convertToDate: (value: any): Date | null => {
        const m = datefns.toDate(value)
        if (!datefns.isValid(m)) {
            return null
        } 
        return m
    },
    convertToIsoDate: (value: any): Date | null => {
        // console.log('v', value)
        if (datefns.isDate(value)) return value
        
        const m = datefns.parseISO(value)
        if (!datefns.isValid(m)) {
            return null
        } 
        return m
    },
    parseIsoDate: (value: any, convertorParam = '', returnOrigVal = true): any => {
        const formatting = (convertorParam && convertorParam !== '') ? convertorParam : SharedService.uiText(9)

        const m = (value === '') ? new Date() : (value instanceof Date) ? value : datefns.parseISO(value)
        if (!datefns.isValid(m)) {
            return (returnOrigVal) ? value : null
        }
        return datefns.lightFormat(m, formatting)    // value
    },
    formatIsoDate: (value: any, parseString = '', returnOrigVal = true): any => {
        const formatting = (parseString && parseString !== '') ? parseString : SharedService.uiText(9)

        const m = (value === '') ? new Date() : (value instanceof Date) ? value : datefns.parse(value, formatting, new Date())
        if (!datefns.isValid(m)) {
            return (returnOrigVal) ? value : null
        }
        return datefns.formatISO(m)    // value
    },  
    yesterday: () : any => {
        return DateService.dateAdd(new Date(), -1, 'd', true, SharedService.uiText(10))
    },
    dateDiff: (val1: any, val2: any, param: string): number => {
        const dat1 = (val1 === '') ? new Date() : datefns.toDate(val1)
        if (!datefns.isValid(dat1)) { return -100 }

        const dat2 = (val2 === '') ? new Date() : datefns.toDate(val2)
        if (!datefns.isValid(dat2)) { return -200 }

        switch (param) {
            case 'y':
                return datefns.differenceInYears(dat2, dat1)
            case 'm':
                return datefns.differenceInMonths(dat2, dat1)
            case 'd':
                return datefns.differenceInDays(dat2, dat1)
            case 'h':
                return datefns.differenceInHours(dat2, dat1)
            case 'mm':
                return datefns.differenceInMinutes(dat2, dat1)
            case 's':
                return datefns.differenceInSeconds(dat2, dat1)
            default:
                return -200
        }
    },
    dateAdd: (val1: any, val2: number, param: string, convertToString = false, convertParam = ''): Date | string => {
        const dat1 = (val1 === '') ? new Date() : datefns.toDate(val1)
        if (!datefns.isValid(dat1)) { return val1 }

        let tmpDate
        switch (param.toLowerCase()) {
            case 'y':
                   tmpDate = datefns.addYears(dat1, val2)
                break
            case 'm':
                tmpDate = datefns.addMonths(dat1, val2)
                break
            case 'd':
                tmpDate = datefns.addDays(dat1, val2)
                break
            case 'h':
                tmpDate = datefns.addHours(dat1, val2)
                break
            case 'mm':
                tmpDate = datefns.addMinutes(dat1, val2)
                break
            case 's':
                tmpDate = datefns.addSeconds(dat1, val2)
                break
            case 'ms':
                tmpDate = datefns.addMilliseconds(dat1, val2)
                break
        }
        if (convertToString) { tmpDate = DateService.convertDate(tmpDate, convertParam) }
        return tmpDate
    },
    dateStart: (val1: any, param: string, convertToString = false, convertParam = ''): Date | string | number => {
        const dat1 = (val1 === '') ? new Date() : datefns.toDate(val1)
        if (!datefns.isValid(dat1)) { return val1 }

        let tmpDate
        switch (param.toLowerCase()) {
            case 'y':
                   tmpDate = datefns.startOfYear(dat1)
                break
            case 'm':
                tmpDate = datefns.startOfMonth(dat1)
                break
            case 'd':
                tmpDate = datefns.startOfDay(dat1)
                break
            case 'h':
                tmpDate = datefns.startOfHour(dat1)
                break
            case 'mm':
                tmpDate = datefns.startOfMinute(dat1)
                break
            case 's':
                tmpDate = datefns.startOfSecond(dat1)
                break
            case 'ms':
                tmpDate = datefns.startOfSecond(dat1)
                break
        }
        if (convertToString) { tmpDate = DateService.convertDate(tmpDate, convertParam) }
        return tmpDate
    },
    dateEnd: (val1: any, param: string, convertToString = false, convertParam = ''): Date | string => {
        const dat1 = (val1 === '') ? new Date() : datefns.toDate(val1)
        if (!datefns.isValid(dat1)) { return val1 }

        let tmpDate
        switch (param.toLowerCase()) {
            case 'y':
                   tmpDate = datefns.endOfYear(dat1)
                break
            case 'm':
                tmpDate = datefns.endOfMonth(dat1)
                break
            case 'd':
                tmpDate = datefns.endOfDay(dat1)
                break
            case 'h':
                tmpDate = datefns.endOfHour(dat1)
                break
            case 'mm':
                tmpDate = datefns.endOfMinute(dat1)
                break
            case 's':
                tmpDate = datefns.endOfSecond(dat1)
                break
            case 'ms':
                tmpDate = datefns.endOfSecond(dat1)
                break
        }
        if (convertToString) { tmpDate = DateService.convertDate(tmpDate, convertParam) }
        return tmpDate
    },
    
}

export default DateService

