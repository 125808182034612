import React, { PropsWithChildren } from "react"
import { useDrop } from "react-dnd"

import { IDragItem, IDropContainer } from "@MODEL/uiModel"

const DropContainer: React.FC<PropsWithChildren<IDropContainer>> = (props: PropsWithChildren<IDropContainer>) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: props.allowTypes,
    drop(item: IDragItem, monitor) {
      const dropResult: {dropEffect: string, isProcessed?: boolean} | null = monitor.getDropResult()

      // console.log('is drop', dropResult, item, monitor.getClientOffset(), drop)

      if (!dropResult?.isProcessed) props.dropHandler(item)
      return {isProcessed: true}
    },
    // hover(item: DragObjectWithType, monitor: DropTargetMonitor) {
    //   if (monitor.isOver({shallow: true})) {
    //     console.log('hhh', item, monitor.getClientOffset(), monitor.getDifferenceFromInitialOffset(), drop.name)
    //   }

    // },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  // const isActive = (isOverCurrent || (isOver && props.greedy)) && canDrop
  const isActive = isOver && canDrop
// console.log('pro', props.xtraStyles)
  return (<div ref={drop} className={[props.xtraClasses, (isActive) ? 'active' : (canDrop) ? 'canDrop' : ''].join(' ')} style={{ ...props.xtraStyles }}>{props.children}</div>)
}

export default DropContainer
