import React, { useEffect, memo, useState } from "react"

import * as _ from 'lodash'

import DBService, { useService } from '@SERVICE/dbService'
import SharedService from "@SERVICE/sharedService"

import UiFormItem from "@UI/forms/uiFormItem"
import { DMeta, EFormItemTypes, EMetaTypes, IFormItemResponse, IMeta, IMetaHasLanguage, IMetaType, IValueOptions } from "@MODEL/uiModel"
import UiButton from "@UI/dashboard/button"
import DragContainer from "./dragContainer"
import { forkJoin, Observable, Subject } from "rxjs"
import { switchMap } from "rxjs/operators"
import { IGlobalState } from "@MODEL/stateModel"

const reloadSubject: Subject<void> = new Subject()

const MetaProps: React.FC = () => {
    const dbState: IGlobalState = useService()[0]
    const [currMeta, setMeta] = useState<Partial<IMeta>>({ ...DMeta, folderId: dbState.currFolder?.id || dbState.rootUuid })
    const [currInitMeta, setInitMeta] = useState<Partial<IMeta>>({ ...DMeta, folderId: dbState.currFolder?.id || dbState.rootUuid })
    const [currMetaTypes, setMetaTypes] = useState<IValueOptions[]>()
    const [isEdit, setEditMode] = useState<boolean>(false)

    const saveData = (): void => {
        if (!currMeta.value?.length) return

        const obsToProcess: Observable<IMeta[] | IMetaHasLanguage[]>[] = []
        const tmpMetaObj: Partial<IMeta> = { type: currMeta.type, value: currMeta.value, folderId: currMeta.folderId, active: currMeta.active }

        if (isEdit) {
            obsToProcess.push(DBService.dbRequest('PATCH', `generic/folder_meta/${currMeta.id}/id`, tmpMetaObj))

            currMeta.translations && currMeta.translations.forEach((x, i) => {
                 if (!(x && x.length)) {
                    obsToProcess.push(DBService.dbRequest('DELETE', 'generic/folder_metaHasLanguage', undefined, { filter: `metaId={${currMeta.id}} AND languageId=${i}` }))
                } else {
                    console.log('hier4', currInitMeta.translations)
                    if (currInitMeta.translations && currInitMeta.translations[i]) {
                        obsToProcess.push(DBService.dbRequest('PATCH', 'generic/folder_metaHasLanguage', { value: x } as Partial<IMetaHasLanguage>, { filter: `metaId={${currMeta.id}} AND languageId=${i}` }))
                    } else {
                        obsToProcess.push(DBService.dbRequest('POST', 'generic/folder_metaHasLanguage', { value: x, languageId: i, metaId: currMeta.id } as Partial<IMetaHasLanguage>))
                    }
                }
            })
        } else {
            obsToProcess.push(DBService.dbRequest('POST', 'generic/folder_meta', tmpMetaObj))

            currMeta.translations && currMeta.translations.forEach((x, i) => {
                if (!(x && x.length)) return
                
                obsToProcess.push(DBService.dbRequest('POST', 'generic/folder_metaHasLanguage', { value: x, languageId: i, metaId: currMeta.id } as Partial<IMetaHasLanguage>))
            })
        }

        forkJoin(obsToProcess).subscribe(() => {
            // setInitMeta({ ...DMeta })
            // resetEditMode()
            reloadSubject.next()
        })
    }

    const deleteData = () => {
        DBService.dbRequest('DELETE', 'generic/folder_metaHasLanguage', undefined, { filter: `metaId={${currMeta.id}}` })
            .pipe(
                switchMap(() => DBService.dbRequest('DELETE', `generic/folder_meta/${currMeta.id}/id`))
            )
            .subscribe((x: IMeta[]) => {
                console.log(`deleted ${x[0].id}`)
                resetEditMode()
                reloadSubject.next()
            })
    }

    const updateData = (x: IFormItemResponse) => {
        if (!x.field?.length) return

        const tmpCloneItem = { ...currMeta }
        tmpCloneItem[x.field]=x.value

        setMeta(tmpCloneItem)
    }

    const updateLanguageData = (index: number, x: IFormItemResponse) => {
        const tmpCloneItem = { ...currMeta }
        if (!tmpCloneItem.translations) tmpCloneItem.translations = []
        tmpCloneItem.translations[index]=x.value

        console.log(tmpCloneItem)
        setMeta(tmpCloneItem)
    }


    const updateCboData = (x: IFormItemResponse) => {
        const tmpCloneItem = { ...currMeta }
        tmpCloneItem[x.field]=x.value[0]
        setMeta(tmpCloneItem)
    }


    const selectMeta = (id: string): void => {
        forkJoin([
            DBService.dbRequest('GET', `generic/folder_meta/${id}/id`),
            DBService.dbRequest('GET', 'generic/folder_metaHasLanguage', undefined, { filter: `metaId={${id}}` }),
        ])
        .subscribe((x: [IMeta[], IMetaHasLanguage[]]) => {
            if (!x[0].length) return

            const meta = x[0][0]
            meta.translations = []
            x[1].forEach(y => meta.translations[y.languageId] = y.value)

            setEditMode(true)
            setInitMeta(_.cloneDeep(currMeta))
            setMeta(meta)
        })
    }

    const resetEditMode = () => {
        setMeta({ ...DMeta, folderId: dbState.currFolder?.id || dbState.rootUuid })
        // setInitMeta({ ...DMeta })
        setEditMode(false)
    }

    useEffect(() => {
        DBService.dbRequest('GET', 'generic/folder_metaType', undefined, { order: 'id asc' }).subscribe((x: IMetaType[]) => {
            const tmpMetaTypeData: IValueOptions[] = []
            x.forEach((y) => {
                tmpMetaTypeData.push({id: y.id, label: y.id})
            })
            setMetaTypes(tmpMetaTypeData)
        })
    }, [])

    useEffect(() => {
        return
    }, [currMeta])

    const hasEditor = [EMetaTypes.content].includes(currMeta?.type || EMetaTypes.title)
    const buttons: JSX.Element = !isEdit
                                    ? <UiButton clickHandler={saveData}>{SharedService.uiText(58)}</UiButton> 
                                    : <div className="FLEX-ROW SMALLGAP">
                                        <UiButton clickHandler={saveData}>{SharedService.uiText(57)}</UiButton> 
                                        <UiButton clickHandler={resetEditMode} xtraClasses={["second"]}>{SharedService.uiText(28)}</UiButton> 
                                        <UiButton clickHandler={deleteData} xtraClasses={["second"]}><i className="gi-icon" gi-icon="delete-bin" /></UiButton> 
                                      </div>

    return (
        <div className="FLEX-ROW">
            <div className="sidebar">
                <DragContainer key="folder_meta" table='folder_meta' clickHandler={selectMeta} reloadSubject={reloadSubject}></DragContainer>
            </div>
            <div className="FLEX-COLUMN">
                <div className="RIGHTFLEX">{buttons}</div>
                <div className="formbar GROW-MAX">

                    <div key={`metaId-${currMeta?.id}`} className="FLEX-COLUMN">
                        <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(8), type: EFormItemTypes.asText, value: currMeta?.id}, showLabel: true}} />
                        <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(51), type: EFormItemTypes.select, valueOptions: currMetaTypes, hasFilter: true, value: currMeta?.type, field: 'type'}, showLabel: true, changeHandler:updateCboData}} />
                        <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(52), type: hasEditor ? EFormItemTypes.editor : EFormItemTypes.text, value: currMeta?.value, field:'value'}, showLabel: true, blurHandler:updateData }} />
                        <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(55), type: EFormItemTypes.switch, value: currMeta?.active, field: 'active' }, showLabel: true, changeHandler:updateData }} />
                        <div className="SEPARATOR"></div>
                        <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(74), type: EFormItemTypes.select, valueOptions: [{ id: dbState.rootUuid, label: SharedService.uiText(75) }, ...dbState.globalFolders.map((x) => {return {id: x.id, label: x.descr} as IValueOptions})], hasFilter: true, value: currMeta?.folderId || dbState.currFolder?.id, field: 'folderId'}, showLabel: true, changeHandler:updateCboData}} />
                        <UiFormItem formItemProps={{formItem: {label: `${SharedService.uiText(53)} (${SharedService.uiText(56)})`, type: hasEditor ? EFormItemTypes.editor : EFormItemTypes.text, value: currMeta?.translations && currMeta?.translations[1], field: 'value'}, showLabel: true, blurHandler:(resp) => updateLanguageData(1, resp) }} />
                        <UiFormItem formItemProps={{formItem: {label: `${SharedService.uiText(54)} (${SharedService.uiText(56)})`, type: hasEditor ? EFormItemTypes.editor : EFormItemTypes.text, value: currMeta?.translations && currMeta?.translations[2], field: 'value'}, showLabel: true, blurHandler:(resp) => updateLanguageData(2, resp) }} />
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default memo(MetaProps)