import React, { memo, useEffect, useState} from "react"

import * as _ from 'lodash'
import { forkJoin, Subject } from "rxjs"

import DBService, { useService }  from '@SERVICE/dbService'
import SharedService from "@SERVICE/sharedService"

import DragItem from "./dragItem"
import ClickItem from "./clickItem"
import UiFormItem from "@UI/forms/uiFormItem"

import { IGlobalState, TFuncVoid } from "@MODEL/stateModel"
import { EFormItemTypes, IMeta, IMetaType, IValueOptions } from "@MODEL/uiModel"

const DragContainer: React.FC<{table: string, clickHandler?: TFuncVoid, reloadSubject?: Subject<void>}> = (props: {table: string, clickHandler?: TFuncVoid, reloadSubject?: Subject<void>}) => {
    const dbState: IGlobalState = useService()[0]
    const [currData, setData] = useState<any[]>([])
    const [currFilterKeyData, setFilterKeyData] = useState<IValueOptions[]>([])
    const [filterObj, setFilterObj] = useState<{text: string, keys: {[key: string]: string[]}}>({text: '', keys: {}})
    const [reloadTrigger, setReloadTrigger] = useState<number>(0)
    
    const itemLimit = 100

    const loadData = () => {
        const tmpFilter: string[] = ['active=1', `folderId IN ({${dbState.rootUuid}}, {${dbState.currFolder.id}})`]

        if (filterObj.text?.length) tmpFilter.push(`value like {%${filterObj.text}%}`)
        _.forEach(filterObj.keys, (v, k) => {
            tmpFilter.push(`${k} IN (${v.map(x => `{${x}}`)})`)
        })

        forkJoin([
            DBService.dbRequest('GET', 'generic/folder_metaType', undefined, { order: 'id asc' }),
            DBService.dbRequest('GET', `generic/${props.table}`, undefined, { filter: tmpFilter.join(' AND '), top: itemLimit.toString(), order: 'value asc' }),
        ])
            .subscribe((x: [IMetaType[], IMeta[]]) => {
                setData(x[1])
                
                const tmpKeyData: IValueOptions[] = []
                x[0].forEach((y) => {
                    tmpKeyData.push({id: y.id, label: y.id})
                })
                setFilterKeyData(tmpKeyData)
            })
    }

    useEffect(() => {
        props.reloadSubject && props.reloadSubject.subscribe(() => setReloadTrigger(Math.random()*5000))
    }, [])

    useEffect(() => {
        loadData()
    }, [filterObj, reloadTrigger])

    const filterValuesByText = _.debounce((filtervalue = '') => {
        const tmpObj = _.clone(filterObj)
        tmpObj.text  = filtervalue
        setFilterObj(tmpObj)
    }, 200)

    const filterValuesByKey = (key: string, value: {value: string[]}) => {
        const tmpObj = _.clone(filterObj)
        tmpObj.keys[key] = value.value
        setFilterObj(tmpObj)
    }

    // const filterValues = (tmpData?: any[]) => {
    //     let tmpVal = true
    //     let tmpOptions: any[] = _.filter((tmpData || currData), x => _.reduce(filterObj.keys, (p, v, k) => !p ? p : v.length === 0 || _.includes(v, x[k]), tmpVal))

    //     tmpOptions = _.filter(tmpOptions, x => new RegExp(SharedService.strToRegex(filterObj.text),'gi').test(x[tableSetup.labelColumn])).slice(0, itemLimit)
    //     setValueOptions(tmpOptions)
    // }

    // console.log(currFilterKeyData)

    const content = 
                <div className="dragContainer">
                    <div className="title RELATIVE FLEX-ROW">
                        <div className="GROW-MAX">{SharedService.uiText(47)}</div>
                        <div><i className="gi-icon suffix btn" gi-icon="refresh" onClick={loadData} /></div>
                    </div>
                    {currFilterKeyData.length &&
                            <div className="filter">
                                <UiFormItem formItemProps={{changeHandler: (e: any) => filterValuesByKey( 'type', e), formItem: {label: 'type', type: EFormItemTypes.select, valueOptions: currFilterKeyData, hasFilter: false, isMultiSelect: true, value: undefined}, showLabel: true}} />
                            </div>
                        
                    } 
                    <div className="filter"><input type="text" placeholder={SharedService.uiText(3)} onChange={(e) => filterValuesByText(e.target.value)} /></div>
                    <div className="dropZone FLEX-COLUMN SMALLGAP">
                        {currData.map((x: IMeta, i: number) => {
                            const actions = {
                                onPointerOver: (e) => SharedService.showPreview(e, x.type, x.value),
                                onPointerOut: () => SharedService.hidePreview()
                            }

                            if (props.clickHandler) {
                                return <ClickItem key={`clickItem-Meta-${i}`}
                                            id={x.id}
                                            name={x.value}

                                            dropProps={{ clickHandler: props.clickHandler }}
                                        >
                                            <div
                                                { ...actions }
                                            >
                                                {x.value}
                                            </div>
                                        </ClickItem>
                            } else {
                                // const actions = {
                                //     onPointerOver: (e) => SharedService.showPreview(e, x.type, x.value),
                                //     onPointerOut: () => SharedService.hidePreview()
                                // }

                                return <DragItem  key={`dragItem-Meta-${i}`}
                                    id={x.id}
                                    name={x.value}
                                    container=''
                                    type='meta'
                                    isDropped={false}
                                >
                                    <div className="FLEX-COLUMNS SMALLGAP"
                                        { ...actions }
                                    >
                                        <div className="FLEX-ROW CENTERFLEX-V">
                                            <i className="gi-icon prefix" gi-icon="drag" />
                                            <div className="GROW-MAX ELLIPSIS"
                                                
                                            >{x.value}</div>
                                        </div>

                                        {/* onPointerOver: (e: PointerEvent<HTMLDivElement>) => SharedService.showTooltip(e, true, { content: props.popupMeta.popupImage?.value, title: props.popupMeta.label?.value, subtitle: DBService.uiText(7) }),
				onPointerOut: () => SharedService.showTooltip(null, false) */}

                                        {/* { x.type === 'image' && 
                                                <div className="FULL">
                                                    <img src=""></img>
                                                </div>
                                        } */}
                                    </div>
                                </DragItem>
                            }
                        })
                        }
                    </div>
                </div>

    return (content)
}

export default DragContainer
