import React, { useState, useEffect} from "react"

import SharedService from "@SERVICE/sharedService"

import { IInputProps } from "@MODEL/uiModel"
import LogService from "@SERVICE/logService"


const InputSwitchComponent: React.FC<IInputProps> = (props: IInputProps) => {
    const [currValue, setValue] = useState<boolean>((typeof(props.formItem.value) === 'boolean') ? props.formItem.value : true)
    
    useEffect(() => {
        if (props.replyValueOnLoad && props.changeHandler) {
            props.changeHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: currValue})
        }
    }, [])

    const updateValue = (e: any) => {
        setValue(e)
        if (props.changeHandler) {
            props.changeHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: e})
        } else {
            LogService.log('geen changeHandler meegegeven')
        }
    }

    return (
        <div className="">
            <label className={['switchbase switch switch-left-right', (props.disabled) ? 'disabled' : ''].join(' ')}>
                <input
                    type="checkbox"
                    className = "switch-input"
                    disabled={props.formItem.disabled}

                    onChange={(e) => updateValue(e.target.checked)}
                    checked={currValue}
                />
                <span className="switch-label" data-on={SharedService.uiText(29)} data-off={SharedService.uiText(30)}></span>
                <span className="switch-handle"></span>
            </label>
        </div>
   )
}

export default InputSwitchComponent