import React, { useState, memo} from "react"

import { IInputProps } from "@MODEL/uiModel"
import LogService from "@SERVICE/logService"


const InputTextareaComponent: React.FC<IInputProps> = (props: IInputProps) => {
    const [currValue, setValue] = useState(props.formItem.value as string || '')
    const [initValue, setInitValue] = useState(props.formItem.value as string || '')

    const updateValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (initValue === currValue) return
        
        if (props.blurHandler) {
            props.blurHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: e.target.value})
        } else {
            LogService.log('geen blurhandler meegegeven')
        }
    }

    const resetInit = () => {
        setInitValue(currValue)
    }

    return (
        <div className="">
            <textarea
                placeholder={props.formItem.placeholder}
                
                onFocus={resetInit}
                onBlur={updateValue}
                onChange={(e) => setValue(e.target.value)}

                disabled={props.formItem.disabled}
                value={currValue}
            />
        </div>
   )
}

export default memo(InputTextareaComponent)