import React, { useState, useEffect} from "react"

import SharedService from "@SERVICE/sharedService"

import { IInputProps } from "@MODEL/uiModel"
import LogService from "@SERVICE/logService"
import _ from "lodash"


const InputColorComponent: React.FC<IInputProps> = (props: IInputProps) => {
    const [currValue, setValue] = useState<string>(props.formItem.value)
    
    useEffect(() => {
        if (props.replyValueOnLoad && props.changeHandler) {
            props.changeHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: currValue})
        }
    }, [])

    const updateValue = _.debounce((e: string) => {
        setValue(e)
        if (props.changeHandler) {
            props.changeHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: e})
        } else {
            LogService.log('geen changeHandler meegegeven')
        }
    }, 300)

    const resetDefault = (value: string): void => {
        updateValue(value)
    }

    return (
        <div className="FLEX-ROW">
            <div className="CENTERFLEX">
                <input type="color" 
                    placeholder={props.formItem.placeholder}

                    onChange={(e) => updateValue(e.target.value)}
                    
                    disabled={props.formItem.disabled}

                    value={currValue}
                />
            </div>
            <div className="CENTERFLEX-V">{currValue}</div>
            <div className="CENTERFLEX-V FLEX-ROW SMALLGAP RIGHTFLEX GROW-MAX">
                <i className="gi-icon" gi-icon={'reset'} onClick={() => resetDefault(props.formItem.value)} title={ SharedService.uiText(43) }></i>
                {props.formItem.default && <i className="gi-icon" gi-icon={'default'} onClick={() => resetDefault(props.formItem.default as string)} title={ SharedService.uiText(44) }></i> }
            </div>
        </div>
   )
}

export default InputColorComponent