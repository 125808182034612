import React, {useEffect} from "react"

import DBService, { useService } from '@SERVICE/dbService'
import FolderService, {pageSubject} from "@SERVICE/folderService"

import { IGlobalState } from "@MODEL/stateModel"
import UiButton from "@UI/dashboard/button"
import SharedService from "@SERVICE/sharedService"
import { EFormComponentTypes, EFormItemTypes, EMutationTypes, IFormItemResponse, IValueOptions } from "@MODEL/uiModel"
import { IFolderPage } from "@MODEL/folderModel"
import UiFormItem from "@UI/forms/uiFormItem"
import _ from "lodash"

import MetaPicker from "@COMP/settings/metaPicker"

const PageProps: React.FC<{ page: IFolderPage, pageIndex: number }> = (props: { page: IFolderPage, pageIndex: number }) => {
    const dbState: IGlobalState = useService()[0]
   
    useEffect(() => {
        // pageSubscription = pageSubject.pipe(filter(x => x === props.page.id)).subscribe(x => {
        //     console.log('update page - content', x)
        // })

        // return () => {
        //     pageSubscription.unsubscribe()
        // }
    }, [])

    const updatePageData = (x: IFormItemResponse) => {
        if (!(x.value && x.field)) return

        DBService.dbRequest('PATCH', `generic/folder_pages/${props.page.id}/id`, { [x.field]: x.value } ).subscribe((x: IFolderPage[]) => {
            if (!x.length) return

            const currPages = dbState.currPages
            const tmpPage = _.find(currPages, y => y.id === x[0].id)
            // console.log(x, currPages, tmpPage)
            if (!tmpPage) return

            _.forEach(x[0], (v, k) => tmpPage[k]=v)
            // tmpPage = {...tmpPage, ...x}

            DBService.pushState({ currPages: [...currPages] })
            pageSubject.next(props.page.id)
        })
    }

    const openModal = (formComponentType: EFormComponentTypes): void => {
        let tmpContent: JSX.Element

        switch (formComponentType) {
            case EFormComponentTypes.metas:
                tmpContent = <MetaPicker itemId={props.page.id} itemIdField='pageId' table='folder_pageHasMeta'></MetaPicker>
                break
            default:
                tmpContent = <div>Unknown component</div>
                break
        }
        
        DBService.pushState({ modal: { show: true, content: tmpContent }})
    }

    return (
        <div className="FLEX BETWEENFLEX">
            <div className="FLEX-COLUMN">
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(12), type: EFormItemTypes.select, valueOptions: dbState.globalPageTemplates.map(x => { return {id: x.id, label: x.descr} as IValueOptions}), field: 'pageTemplateId', value: props.page.pageTemplateId}, showLabel: true, changeHandler: updatePageData }} />
                {/* <UiButton clickHandler={() => FolderService.mutatePage(EMutationTypes.edit)} disabled={dbState.currPages.length === 0}><i className="gi-icon prefix" gi-icon="edit" /></UiButton> */}
            </div>
            <div className="FLEX-COLUMN">
                <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(8), type: EFormItemTypes.asText, value: props.page.id}, showLabel: true}} />
                {/* <UiButton clickHandler={() => FolderService.mutatePage(EMutationTypes.edit)} disabled={dbState.currPages.length === 0}><i className="gi-icon prefix" gi-icon="edit" /></UiButton> */}
            </div>
            <div className="FLEX-ROW SMALLGAP">
                <UiButton clickHandler={() => openModal(EFormComponentTypes.metas)}><i className="gi-icon" gi-icon="list" /></UiButton>
                <UiButton clickHandler={() => FolderService.mutatePage(EMutationTypes.move, { id: props.page.id, moveStep: -1 })} disabled={props.pageIndex === 0}><i className="gi-icon" gi-icon="chevron-up" /></UiButton>
                <UiButton clickHandler={() => FolderService.mutatePage(EMutationTypes.move, { id: props.page.id, moveStep: 1 })} disabled={props.pageIndex === dbState.currPages.length-1}><i className="gi-icon" gi-icon="chevron-down" /></UiButton>
            </div>

            {/* <PagePager /> */}
            <div className="FLEX-ROW SMALLGAP">
                <UiButton clickHandler={() => FolderService.mutatePage(EMutationTypes.add, { id: props.page.id, pageProps: { order: props.pageIndex + 1 }  } )} ><i className="gi-icon prefix" gi-icon="plus-square" />{SharedService.uiText(18)}</UiButton>
                <UiButton clickHandler={() => FolderService.mutatePage(EMutationTypes.delete, { id: props.page.id, pageProps: { order: props.pageIndex } })} disabled={dbState.currPages.length === 1}><i className="gi-icon" gi-icon="delete-bin" /></UiButton>
            </div>
        </div>
    )
}

export default PageProps
