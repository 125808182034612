import React from "react"
// import { TransitionGroup } from "react-transition-group"
import { useService } from '@SERVICE/dbService'

// import EntryTransition from "@UI/animations/entryTransition"
import PageContent from "./pages/pageContent"
import PageProps from "./pages/pageProps"

import { IGlobalState } from "@MODEL/stateModel"

const PageWrapper: React.FC = () => {
    const dbState: IGlobalState = useService()[0]

    const content: JSX.Element = (!dbState.currFolder) ? 
                    <div className="FLEX-COLUMN CENTER GROW-MAX pageContainer">No folder selected</div>
                    :
                    <div className="FLEX-COLUMN GROW-MAX pageContainer">
                        { dbState.currPages.map((x, i) => (
                            <div key={`PageContainer-${i}`} className="FLEX-COLUMN NOGAP page" style={{ zIndex: dbState.currPages.length-i }}>
                                <div className="pageWrapper RELATIVE"><PageContent key={`Page-${x.id}`} page={x} /></div>
                                <PageProps page={x} pageIndex={i} />
                            </div>
                        )
                        )}
                    </div>

    return (content)
}

export default PageWrapper
