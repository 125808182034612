import React, { useState, useEffect, useRef, memo} from "react"

import * as _ from 'lodash'

import SharedService from "@SERVICE/sharedService"

import { IInputProps, IValueOptions } from "@MODEL/uiModel"
import { useDetectClickedOutside} from "@SERVICE/hooks/clickedOutsideHook"
import LogService from "@SERVICE/logService"


const InputSelectComponent: React.FC<IInputProps> = (props: IInputProps) => {
    const [currValue, setValue] = useState((props.formItem.value && props.formItem.value !== '') ? [props.formItem.value] : [])
    const [currValueOptions, setValueOptions] = useState(props.formItem.valueOptions || [])
    const [showDropdown, setDropdown] = useState(props.formItem.isOpenOnLoad)
    const ref: React.RefObject<HTMLDivElement> = useRef(null)

    const { clickedOutside}  =  useDetectClickedOutside(ref, !props.formItem.isOpenOnLoad)

    useEffect(() => {
        if (props.replyValueOnLoad && props.changeHandler) {
            props.changeHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: currValue })
        }
    }, [])
    
    useEffect(() => {
        setValueOptions(props.formItem.valueOptions as IValueOptions[])
    }, [props.formItem.valueOptions])

    const toggleDropdown = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setDropdown(!showDropdown)
    }

    const filterValues = _.debounce((filtervalue = '') => {
        const tmpOptions: IValueOptions[] = _.filter(_.clone(props.formItem.valueOptions) || [], (x: IValueOptions) => new RegExp(SharedService.strToRegex(filtervalue),'gi').test(x.label))
        setValueOptions(tmpOptions)
      }, 200)

    if (clickedOutside && showDropdown) {
        toggleDropdown() 
    } 

    const updateValue = async (e: string | number) => {
        let tmpValues = [...currValue]

        if (props.formItem.isMultiSelect) {
            const tmpDelValues = _.remove(tmpValues, x => x === e)
            if (tmpDelValues.length === 0) tmpValues.push(e)
        } else {
            tmpValues = (tmpValues[0] === e && props.formItem.allowClear) ? [] : [e]
            toggleDropdown() 
        }
        setValue(tmpValues)
        if (props.changeHandler) {
            props.changeHandler({ formitemId: props.formItem?.id || '', formitemLabel: props.formItem?.label || '', field: props.formItem?.field || '', value: tmpValues })
        } else {
            LogService.log('Geen changeHandler meegegeven.')
        }
    }

    const currValues = (currValue.length > 0) ? currValue.map(x => _.find(currValueOptions, y => y.id === x)?.label || SharedService.uiText(50)).join(', ') : props.formItem.placeholder || SharedService.uiText(49)

    return (
        <div ref={ref} className="RELATIVE NOSELECT dropdown">
            <div className="values ELLIPSIS"
                onClick={toggleDropdown}>{currValues}</div>
            {showDropdown && 
                <div className="ABSOLUTE options">
                {props.formItem.hasFilter &&
                    <div className="filter"><input type="text" placeholder={SharedService.uiText(3)} onChange={(e) => filterValues(e.target.value)} /></div>

                }
                <ul>
                {currValueOptions.map(x => {
                    const isActive = _.includes(currValue, x.id)
                    const tmpClasses: string[] = [(isActive) ? 'active' : '', (x.isDisabled) ? 'disabled' : '']
                    const optionIcon: string = (isActive) ? 'check-circle' : 'circle'

                    return (
                        <li 
                            key={x.id}
                            onClick={() => {if (!x.isDisabled) updateValue(x.id)}}

                            className={tmpClasses.join(' ')}
                            >
                            <i className="gi-icon prefix" gi-icon={optionIcon} />
                            {x.label}
                        </li>
                    )
                })

                }
               </ul> </div>
            }
        </div>
   )
}

export default memo(InputSelectComponent)