import React, { memo } from "react"
import { useDrag } from "react-dnd"
import _ from "lodash"

import DBService, { imgProps, useService } from '@SERVICE/dbService'
import SharedService from "@SERVICE/sharedService"

import { IFolderItem, IFolderPageTemplate, IProduct } from "@MODEL/folderModel"
import { IGlobalState } from "@MODEL/stateModel"
import UiButton from "@UI/dashboard/button"
import UiFormItem from "@UI/forms/uiFormItem"
import { EFormComponentTypes, EFormItemTypes, EMutationTypes, IFormItemResponse, IValueOptions } from "@MODEL/uiModel"
import FolderItemProps from "@COMP/settings/folderItemProps"
import MetaPicker from "@COMP/settings/metaPicker"
import FolderService from "@SERVICE/folderService"

const ProductDetail: React.FC<{ product: IProduct, container: string, folderItem?: IFolderItem, editProps?: { enableEdit: boolean, pageTemplate: IFolderPageTemplate} }> = (props: { product: IProduct, container: string, folderItem?: IFolderItem, editProps?: { enableEdit: boolean, pageTemplate: IFolderPageTemplate }}) => {
    const dbState: IGlobalState = useService()[0]
    const showDelete = props.container !== ''

    let maxCols = 1
    let maxRows = 1
    
    if (props.editProps && props.folderItem) {
        maxCols = (props.editProps.pageTemplate.cols - props.folderItem.order % props.editProps.pageTemplate.cols)% props.editProps.pageTemplate.cols + 1 
        maxRows = props.editProps.pageTemplate.rows - Math.ceil(props.folderItem.order / props.editProps.pageTemplate.cols) + 1
    }

    const [{ opacity }, drag] = useDrag({
        type: 'product',
        item: { id: props.folderItem?.id, productId: props.product?.id, name: props.product?.title, container: props.container, type: 'product' },
        collect: (monitor: any) => ({
          opacity: monitor.isDragging() ? 0.6 : 1,
        }),
      })

    const updateFolderItem = (x: IFormItemResponse): void => {
        if (!(x.value && x.field && props.folderItem)) return

        const tmpObj: Partial<IFolderItem> = { [x.field]: x.value }

        if (['rows', 'cols'].includes(x.field) && props.editProps) {
            tmpObj.startRow = Math.ceil(props.folderItem.order/props.editProps.pageTemplate.cols)
            tmpObj.startCol = props.folderItem.order % props.editProps.pageTemplate.cols
        }

        DBService.dbRequest('PATCH', `generic/folder_items/${props.folderItem.id}/id`, tmpObj ).subscribe((x: IFolderItem[]) => {
            updateFolderItemState(x)
            // DBService.pushState({ currFolder: x })
        })
    }

    const deleteFolderItem = (): void => {
        if (!props.folderItem) return
        updateFolderItemState()
        
        FolderService.mutateItem(EMutationTypes.delete, { id: props.folderItem?.id })
    }

    const updateFolderItemState = (x?: IFolderItem[]): void => {
        const currItemKey: keyof IGlobalState = (props.container === 'waiting') ? 'currWachtItems' : 'currItems'
        const currItems = dbState[currItemKey] as IFolderItem[]
        _.remove(currItems, x => x.id === props.folderItem?.id)
        DBService.pushState({ [currItemKey]: [...(x || []), ...currItems] })
    }

    const openModal = (formComponentType: EFormComponentTypes): void => {
        if (!props.folderItem) return

        let tmpContent: JSX.Element

        switch (formComponentType) {
            case EFormComponentTypes.formitemSettings:
                tmpContent = <FolderItemProps item={props.folderItem} updateHandler={updateFolderItemState}></FolderItemProps>
                break
            case EFormComponentTypes.metas:
                tmpContent = <MetaPicker itemId={props.folderItem.id} itemIdField='itemId' table='folder_itemHasMeta'></MetaPicker>
                break
            default:
                tmpContent = <div>Unknown component</div>
                break
        }
        
        DBService.pushState({ modal: { show: true, content: tmpContent }})
    }

    const imgUrl = props.product?.thumb ? `${imgProps.url}/${props.product.thumb}?${imgProps.suffix.small}` : ''
    const iconDir = props.folderItem?.isWaiting ? 'FLEX-ROW' : 'FLEX-COLUMN'

    const content = !props.product ? <span>Empty</span> : 
        <div ref={drag} className="FLEX-COLUMN RELATIVE productCard" style={{ opacity }}>
            <div className="FLEX-ROW RELATIVE productCard">
                <div><img id="thumb" src={imgUrl} /></div>
                <div className="FLEX-COLUMN NOGAP">
                    <div id="title">{props.product?.title}</div>
                    <div>{SharedService.uiText(8)}: {props.product.id}</div>
                    <div>{SharedService.uiText(21)}: {props.product.code}</div>
                    <div>{SharedService.uiText(22)}: {props.product.ean}</div>
                </div>
            </div>
            <div className="FLEX-ROW SMALLGAP RIGHTFLEX">
                { 
                (props.editProps) && 
                    <div className="FLEX-COLUMN SMALLGAP">
                        <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(12), type: EFormItemTypes.select, valueOptions: _.filter(dbState.globalItemTemplates, x => x.isProduct).map(x => { return {id: x.id, label: x.descr} as IValueOptions}), value: props.folderItem?.itemTemplateId, field: 'itemTemplateId' }, showLabel: true, changeHandler: updateFolderItem}} />
                        <div className="FLEX-ROW BETWEENFLEX">
                            <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(23), type: EFormItemTypes.number, value: props.folderItem?.cols, min: 1, max: maxCols, field: 'cols' }, showLabel: true, changeHandler: updateFolderItem}} />
                            <UiFormItem formItemProps={{formItem: {label: SharedService.uiText(24), type: EFormItemTypes.number, value: props.folderItem?.rows, min: 1, max: maxRows, field: 'rows' }, showLabel: true, changeHandler: updateFolderItem}} />
                        </div>
                    </div>
                }
                { 
                props.folderItem && 
                <div className={[iconDir, "SMALLGAP"].join(' ')}>
                    <UiButton clickHandler={() => openModal(EFormComponentTypes.formitemSettings)}><i className="gi-icon" gi-icon="settings" /></UiButton>
                    <UiButton clickHandler={() => openModal(EFormComponentTypes.metas)}><i className="gi-icon" gi-icon="list" /></UiButton>
                    { showDelete && <UiButton clickHandler={deleteFolderItem}><i className="gi-icon" gi-icon="delete-bin" /></UiButton> }
                </div>
            }
            </div>
        </div>
    return (content)
}

export default memo(ProductDetail)