import React, {Fragment, useEffect, useState} from "react"

import DBService from '@SERVICE/dbService'
import SharedService from "@SERVICE/sharedService"

import UiButton from "@UI/dashboard/button"

import { IUser } from "@MODEL/stateModel"
import UiFormItem from "@UI/forms/uiFormItem"
import { EFormItemTypes, IFormItemResponse } from "@MODEL/uiModel"

const Login: React.FC = () => {
    const [userName, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [errorMsg, setErrorMsg] = useState<string>('')
   
    useEffect(() => {
        // pageSubscription = pageSubject.pipe(filter(x => x === props.page.id)).subscribe(x => {
        //     console.log('update page - content', x)
        // })

        // return () => {
        //     pageSubscription.unsubscribe()
        // }
    }, [])

    const updateState = (x: IFormItemResponse) => {
        if (!(x.field?.length)) return

        switch (x.field) {
            case 'userName':
                setUsername(x.value)
                return
            case 'password':
                setPassword(x.value)
                return
        }
    }

    const login = () => {
        DBService.dbRequest('POST', `login/login/folder_users`, { userName, password }).subscribe((x: IUser[] | string) => {
            if (typeof x === 'string') {
                setErrorMsg(x)
                return  
            }

            if (!x.length) {
                setErrorMsg(SharedService.uiText(73))
                return
            }

            DBService.pushState({ user: x[0] })
        })
    }

    return (
        <div className="FULL FULL-H CENTERFLEX">
            <div className="uiModalContainer">
                <div className="FLEX-COLUMN">
                    <div className="FLEX-ROW">
                        <div id="field"><UiFormItem formItemProps={{formItem: {label: SharedService.uiText(70), type: EFormItemTypes.text, value: userName, field: 'userName'}, showLabel: true, blurHandler: updateState}} /></div>
                    </div>
                    <div className="FLEX-ROW">
                        <div id="field"><UiFormItem formItemProps={{formItem: {label: SharedService.uiText(71), type: EFormItemTypes.password, value: password, field: 'password'}, showLabel: true, blurHandler: updateState}} /></div>
                    </div>
                    { (errorMsg.length > 0) &&
                        <Fragment>
                            <div>{errorMsg}</div>
                            <div className="SEPARATOR"></div>
                        </Fragment>
                    }
                    <div id="button" className="CENTERFLEX">
                        <UiButton clickHandler={login} disabled={ !(userName.length && password.length) }>
                            <div className="FLEX-ROW">
                                <i className="gi-icon" gi-icon="settings" />
                                <span>{SharedService.uiText(72)}</span>
                            </div>
                        </UiButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
