import { IGlobalState } from '@MODEL/stateModel'
import DBService, { useService } from '@SERVICE/dbService'
import React, { PropsWithChildren } from 'react'

// class ModalComponent extends Component<{ backgroundClass?: string[] }> {
const ModalComponent: React.FC = (props: PropsWithChildren<{ backgroundClass?: string[] }>) => {
        const dbState: IGlobalState = useService()[0]

        const closeModal = (): void => {
            DBService.pushState({ modal: { show: false, content: undefined }})
        }

        return (
            <div className={[...(props.backgroundClass || []), "uiModalContainer", "uiModalAbsolute"].join(' ')}>
                <div className="FLEX-COLUMN CENTERFLEX">
                    { !dbState.modal.hideClose &&

                        <div className="FULL RIGHTFLEX">
                            <i onClick={closeModal} className="gi-icon HAND" gi-icon="x-circle" style={{fontSize:'1.4em'}}></i>
                        </div>
                    }
                    {dbState.modal.title && <h3 className="CENTER">{dbState.modal.title}</h3>}
                    {dbState.modal.subtitle && <p className="CENTER">{dbState.modal.subtitle}</p>}
                    <div style={{maxWidth:'100%'}}>{props.children}</div>
                </div>
            </div>
        )
}

export default ModalComponent