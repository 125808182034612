import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { useService } from '@SERVICE/dbService'

import Background from '@UI/general/background'
import UiTooltip from '@UI/dashboard/tooltip'
import SoftContainer from '@COMP/softContainer'
import Login from '@COMP/pages/login'
import ModalComponent from './modalComponent'

import { IGlobalState } from "@MODEL/stateModel"

// class Layout extends Component {
const Layout: React.FC = () => {
    const dbState: IGlobalState = useService()[0]

    const content = dbState.user ? <SoftContainer></SoftContainer> : <Login></Login>

    return (
        <DndProvider backend={HTML5Backend}>
             <Background>
                {content}
            </Background>
            {dbState.tooltip?.show && 
                <UiTooltip
                    hideClose={dbState.tooltip.hideClose || false}
                    title={dbState.tooltip.title}
                    subtitle={dbState.tooltip.subtitle}
                >{dbState.tooltip.content}</UiTooltip>
            }
            {dbState.modal?.show && 
                <ModalComponent>{dbState.modal.content}</ModalComponent>
            }
           
        </DndProvider>
    )
}
 
export default Layout