import React, { useEffect, useState, PropsWithChildren } from "react"


const ToggleContainer: React.FC<PropsWithChildren<{xtraClasses?: string[], label:string, initState?: boolean}>> = (props : PropsWithChildren<{xtraClasses?: string[], label:string, initState?: boolean}>) => {
    const [currValue, setValue] = useState(props.initState)

    const toggleState = () => {
        setValue(!currValue)
    }

    useEffect(() => { return }, [])

    const tmpClasses = ['FLEX-COLUMN uiToggleContainerParent', ...(!currValue ? [] : props.xtraClasses || [])]

    return (
        <div className={tmpClasses.join(' ')}>
            <div id="title" className="uiToggleContainer" onClick={toggleState}>
                {props.label}
                <i className="gi-icon" gi-icon={(currValue) ? 'eye-off' : 'eye'} />
            </div>
            {currValue && 
                <div id="content" className="GROW-MAX">{props.children}</div>
            }
        </div>
    )
}

export default ToggleContainer