import React, { useEffect, useRef, Fragment } from 'react'
// import Parser from 'html-react-parser'

import _ from 'lodash'
import { forkJoin, Subject, Subscription } from 'rxjs'

import DBService, { useService, restCredentials } from '@SERVICE/dbService'
import LogService from '@SERVICE/logService'

import ModalInfo from '@UI/general/modalInfo'
import Layout from '@UI/general/layout'

import { IGlobalLanguage, IGlobalState, ILoadingState, IGlobalText, IGlobalTextHasLanguage } from '@MODEL/stateModel'
import { IFolder, IFolderItemTemplate, IFolderPageTemplate, IFolderTemplate } from '@MODEL/folderModel'


DBService.initService(null, { isLoaded: false, rootUuid: process.env.REACT_APP_ROOT_UUID })

const progressSubject: Subject<number> = new Subject()
let loadingSubscription: Subscription
let progressCounter = 0

// BACKUP DATA voor wanneer DB unreachable of nog niet ingeladen is.
DBService.pushState({
    networklessData: {
        loadingText: ['Loading...', 'Fetching products.', 'Calculating prices!', 'Looking for a hammer...'], 
        errorText: {
            noDB: <span>I&lsquo;m sorry, your internet connection just gave up.<br/>Please try again later...</span>,
            noToken: <span>Specified token or Apolloclient is invalid.<br/>Please fix your provided settings...</span>
        }
    },
    tooltip: {
        show: false,
        hideClose: true,
        followMouse: true,
        content: undefined
    },
    allowDownload: true,
})

let xtraClass: string[] = []

const FolderCreator: React.FC = () => {
    const dbState: IGlobalState = useService()[0]
    const rootRef: React.RefObject<HTMLDivElement> = useRef(null)
    
    if (!loadingSubscription) {
        const loadingState: ILoadingState = {isMobile: false, uiLanguageId: 1, globalLanguages: [], globalText: {}, globalFolders: [], globalFolderTemplates: [], globalPageTemplates: [], globalItemTemplates: [], itemPropsIsVisible: false}

        // HIER CORRECTE VALIDATIE OP ZETTEN EENS TOKEN GEKEND
        // if (!(props.client && props.token)) {
        //     DBService.pushState({DBErrorMsg: {error: true, message: DBService.fetchState().networklessData.errorText.noToken}})
        //     progressCounter = 97
        //     continueLoading()
        // }

        loadingSubscription = progressSubject.subscribe(x => {
            switch(x) {
                case 0: // user verification
                    DBService.dbRequest('POST', 'user/authenticate', restCredentials).subscribe(x => {
                        DBService.setToken(x.message)
                        continueLoading()
                    })
                    break
                case 1: // alg data
                    forkJoin([
                        DBService.dbRequest('GET', 'generic/lang'),
                        DBService.dbRequest('GET', 'generic/folder_globalText'),
                        DBService.dbRequest('GET', 'generic/folder_globalTextHasLanguage', undefined, {filter: `languageId=${loadingState.uiLanguageId}`}),
                    ]).subscribe((x: [IGlobalLanguage[], IGlobalText[], IGlobalTextHasLanguage[]]) => {
                        loadingState.globalLanguages = x[0]

                        if (x[1] && x[2]) {
                            const tmpObj = Object.assign({})
                            x[1].forEach((y: IGlobalText) => {
                                const tmpValue: IGlobalTextHasLanguage = _.find(x[2], z => z.globalTextId === y.id) as IGlobalTextHasLanguage
                                tmpObj[`T${y.id}`] = (tmpValue) ? tmpValue.value : y.value
                            })
                            loadingState.globalText = tmpObj
                        }
                        continueLoading()
                    })                    
                    break
                case 2: // folders + templates
                    forkJoin([
                        DBService.dbRequest('GET', 'generic/folder_folder', undefined, { filter: 'active=1', order: 'dateAdd DESC'}),
                        DBService.dbRequest('GET', 'generic/folder_folderTemplate', undefined, { filter: 'active=1', order :'[order] ASC'}),
                        DBService.dbRequest('GET', 'generic/folder_pageTemplate', undefined, { filter: 'active=1', order :'[order] ASC'}),
                        DBService.dbRequest('GET', 'generic/folder_itemTemplate', undefined, { filter: 'active=1', order :'[order] ASC'}),
                    ])
                    .subscribe((x: [IFolder[], IFolderTemplate[], IFolderPageTemplate[], IFolderItemTemplate[]]) => {
                        loadingState.globalFolders = x[0]
                        loadingState.globalFolderTemplates = x[1]
                        loadingState.globalPageTemplates = x[2]
                        loadingState.globalItemTemplates = x[3]
                        DBService.pushState({isLoaded: true, ...loadingState})
                        continueLoading()
                    })       
                    break
                case 3: // 
                    break
                case 98:
                    LogService.log('Startup stopped due to no token / client.')
                    break
                default:
                case 99: // error fallback + abort sequence
                    LogService.log('Startup stopped due to error.')
                    break
            }
        })  
    }

    const handleResize = _.debounce(() => {
        const isMobile = (rootRef?.current?.offsetWidth || 0) < 961
        xtraClass = [(isMobile) ? "MD" : ""]
        
        if (isMobile !== dbState.isMobile) DBService.pushState({isMobile: isMobile})
    }, 100)


    useEffect(() => {
        progressCounter = 0
        progressSubject.next(progressCounter)

        window.addEventListener('resize',  handleResize)
        handleResize()
    }, [])

    useEffect(() => {
        if (dbState.DBErrorMsg?.error){
            progressCounter = 99
        } 
    }, [dbState.DBErrorMsg])

    const content = (dbState.DBErrorMsg?.error) ?
                <ModalInfo
                    key="error" 
                    icon="spinner.svg"
                    message={dbState.DBErrorMsg?.message}
                />
            :
                (dbState.isLoaded) ? 
                    <Fragment>
                        <Layout></Layout>
                    </Fragment>
                :
                    <ModalInfo 
                        key="loader"
                        message={dbState.networklessData.loadingText}
                        icon="spinner.svg"
                        backgroundClass={[]} 
                    />

      return (
            <div ref={rootRef} className={['rootContainer', ...xtraClass].join(' ')}>{content}</div>
    )
}

const continueLoading = () => {
    progressCounter++
    progressSubject.next(progressCounter)     
}

export default FolderCreator
