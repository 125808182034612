import React, { memo } from "react"

import { IFolderItem, IFolderPageTemplate } from "@MODEL/folderModel"
import { TFuncVoid } from "@MODEL/stateModel"
import UiButton from "@UI/dashboard/button"

import { IDragItem } from "@MODEL/uiModel"
import { TTemplateComponentTypes } from "./templateTypes.type"
import SharedService from "@SERVICE/sharedService"

const NewItemDetail: React.FC<{ container: string, folderItem?: IFolderItem, addHandler: TFuncVoid, editProps?: { enableEdit: boolean, pageTemplate: IFolderPageTemplate} }> = (props: { container: string, folderItem?: IFolderItem, addHandler: TFuncVoid, editProps?: { enableEdit: boolean, pageTemplate: IFolderPageTemplate }}) => {
    const addFolderItem = (componentType: TTemplateComponentTypes): void => {
        const dummyDragItem: IDragItem = {
            container: '',
            productId: 0,
            index: props.folderItem?.order,
            type: componentType,
        }

        props.addHandler(dummyDragItem, props.container, props.folderItem?.order)
    }

    return (
        <div className="FULL-H CENTERFLEX">
            <UiButton clickHandler={() => addFolderItem('generic')}>
                <i className="gi-icon prefix" gi-icon="plus-square"></i>
                <span>{ SharedService.uiText(25)}</span>
            </UiButton>
        </div>
    )
}

export default memo(NewItemDetail)