import React, { useEffect, useState } from "react"
import _ from "lodash"

import DBService, { useService } from '@SERVICE/dbService'

import { IGlobalState } from "@MODEL/stateModel"
import { useDrop } from "react-dnd"
import { IFolderItem, IProduct } from "@MODEL/folderModel"
import { IDragItem } from "@MODEL/uiModel"
import { fetchQuery } from "@MODEL/dbModel"
import { Observable } from "rxjs"
import { TemplateComponentTypes } from "@COMP/product/templateTypes.type"

const templateComponentTypes: TemplateComponentTypes = new TemplateComponentTypes()

const FolderItemList: React.FC = () => {
    const dbState: IGlobalState = useService()[0]
    const [currProducts, setProducts] = useState<IFolderItem[]>([])
    const container = 'waiting'

    const [, drop] = useDrop({
        accept: ['product', 'generic'],
        drop(item: IDragItem, monitor) {
          const dropResult: {dropEffect: string, isProcessed?: boolean} | null = monitor.getDropResult()
          
          if (!dropResult?.isProcessed && item.container !== container) {
              console.log('is drop in wachtljst', item)
              const isNew = item.container === '' || !item.id
              
              const tmpObj: Partial<IFolderItem> = {
                isWaiting: true,
                folderId: dbState.currFolder.id,
              }
              
              let tmpObs: Observable<IFolderItem[]> = DBService.dbRequest('PATCH', `generic/folder_items/${item.id}/id`, tmpObj)

              if (isNew) {
                tmpObj.descr = item.name || '<no name>'
                tmpObj.productId = item.productId
                tmpObj.reactTemplate = item.type

                tmpObs = DBService.dbRequest('POST', 'generic/folder_items', tmpObj)
              }

              tmpObs.subscribe((x: IFolderItem[]) => {
                // Huidige wachtitems updaten
                const currWachtItems = dbState.currWachtItems || []
                _.remove(currWachtItems, y => x.map(z => z.id).includes(y.id))
                const tmpWachtItems: IFolderItem[] = [...x, ...currWachtItems]
                const newState: Partial<IGlobalState> = { currWachtItems: tmpWachtItems }

                // Kijken of het uit de itemlijst kwam
                const currItems = dbState.currItems
                const itemsRemoved = _.remove(currItems, y => x.map(z => z.id).includes(y.id))
                if (itemsRemoved.length) newState.currItems = [...currItems]

                // State updaten
                DBService.pushState(newState)  
              })
          }
    
          return {isProcessed: true}
        },
      })

    useEffect(() => {
        if (!(dbState.currWachtItems?.length)) {
            setProducts([])
            return
        }

        DBService.dbRequest('GET', 'query', undefined, { query: fetchQuery('products', { filter: `languageId=1 AND id IN (${dbState.currWachtItems.map(x => x.productId)})` }) })
            .subscribe((x: IProduct[]) => {
                dbState.currWachtItems.forEach(y => {
                  y.product = _.find(x, z => z.id === y.productId)
                })
                // console.log('prods ', x)
                // x.forEach(y => y.folderItem = _.find(dbState.currWachtItems, z => z.productId === y.id))
                setProducts([...dbState.currWachtItems])
            })
    }, [dbState.currWachtItems])
    

    return (
        <div ref={drop} className="FLEX-COLUMN FULL-H NOSCROLL uiDropContainer" >
            {
                currProducts.map(x => {
                  const TemplateComponent = templateComponentTypes[x.reactTemplate || 'generic']
                  return <TemplateComponent key={x.id} product={x.product} container={container} folderItem={x} />
                })
            }
        </div>
    )
}

export default FolderItemList