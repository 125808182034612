enum ELogTypes {
    regular,
    warning,
    error
} 

const LogService = {
    log : (message: any | any[], logType: ELogTypes = 0) => {
        // if (process.env.DEBUGMODE === 'true') {
            const messages = (Array.isArray(message)) ? message : [message]
            switch(logType) {
                default:
                case ELogTypes.regular:
                    console.log(...messages)
                    break
                case ELogTypes.warning:
                    console.warn(...messages)
                    break
                case ELogTypes.error:
                    console.error(...messages) 
                    break
            }
        // }
    }
}

export default LogService