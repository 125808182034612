import DBService from "@SERVICE/dbService"

export enum EEndpoints {
    rest,
    fso,
}

export type TCustomHeaders = Partial<ICustomHeaders> & HeadersInit

export interface ICustomHeaders {
    'x-filter': string
    'x-top': string
    'x-order': string
    'x-qry': string
}
export interface IErrorMessage {
    error?: boolean
    message?: string
}

export interface IGlobalTextObj {
    key?: string | number,
    text: string | number,
    replace?: IGlobalTextObj[]
}

export interface IRequestOptions {
    filter?: string,
    top?: string,
    order?: string
    query?: string
    endpoint?: EEndpoints
    headers?: TCustomHeaders
}



// QUERIES
// =================================

export const querries = {
    products: `SELECT {{TOP}} FP.* FROM folder_prods FP {{FILTER}} {{ORDER}}`
}

export const fetchQuery = (query: keyof typeof querries, options: IRequestOptions): string => {
    let tmpQry = querries[query]
    tmpQry = tmpQry
        .replace('{{FILTER}}', (options?.filter) ? `WHERE ${DBService.fixFilter(options.filter)}` : '')
        .replace('{{TOP}}', (options?.top) ? `TOP ${DBService.fixFilter(options.top)}` : '')
        .replace('{{ORDER}}', (options?.order) ? `ORDER BY ${DBService.fixFilter(options.order)}` : '')

    return tmpQry
}

