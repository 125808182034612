import React, { Component } from 'react'

class ModalInfo extends Component<{message: JSX.Element | string | JSX.Element[] | string[], icon?: string, backgroundClass?: string[] }> {
        private messages = (Array.isArray(this.props.message)) ? this.props.message : [this.props.message]
        private timer: any

        state = {
            counter: 0,
            currMessage: this.messages[0],
            currIcon: (this.props.icon) ? <div><img alt="" src={require(`@ASSETS/ui/icons/${this.props.icon}`)} /></div> : null
        }

        componentDidMount = (): void => {
            this.chooseMessage()
        }
        
        componentWillUnmount = (): void => {
            clearTimeout(this.timer)
        }

        chooseMessage = (timeoutValue = 1000): void => {
            if (!(this.messages.length > 1)) return 

            this.timer = setTimeout(() => {
                const newCounter = (this.state.counter < this.messages.length-1) ? this.state.counter + 1 : 0
                this.setState({counter: newCounter, currMessage: this.messages[newCounter]})

                this.chooseMessage(Math.random() * 3000 + 1000)
            }, timeoutValue)

        }

        render(): JSX.Element {
            return (
                <div className={[...(this.props.backgroundClass || []), "uiModalContainer"].join(' ')}>
                    <div className="CENTERFLEX">
                        {this.state.currIcon}
                        <div>{this.state.currMessage}</div>
                    </div>
                </div>
            )
        }
}

export default ModalInfo