import { TDragTypes } from "@COMP/product/templateTypes.type";
import React from "react";
import { Observable } from "rxjs";
import { TFuncVoid } from "./stateModel";

// ENUMS
// =======================================
export enum EFormItemTypes {
    text = 'text',
    password = 'password',
    textarea = 'textarea',
    number = 'number',
    select = 'select',
    asText = 'asText',
    switch = 'switch',
    radio = 'radio',
    color = 'color',
    editor = 'editor',
}

// TABLE: metaType
export enum EMetaTypes {
    color = 'color',
    content = 'content',
    headerImage = 'headerImage',
    backgroundImage = 'backgroundImage',
    icon = 'icon',
    image = 'image',
    mask = 'mask',
    prefix = 'prefix',
    subtitle = 'subtitle',
    suffix = 'suffix',
    title = 'title',
}

export enum ERequiredTypes {
    no,
    text,
    number,
}

export enum EMutationTypes {
    add,
    edit,
    delete,
    move,
}

export enum EFormComponentTypes {
    formitemSettings,
    metas,
}

// TYPES
// =======================================
export type TFolderMeta = IMetaConnection & { folderId: string }
export type TFolderTemplateMeta = IMetaConnection & { folderTemplateId: string }
export type TPageMeta = IMetaConnection & { pageId: string }
export type TItemMeta = IMetaConnection & { itemId: string }
export type TItemTemplateMeta = IMetaConnection & { itemTemplateId: string }
export type TAllMetaConnections = TFolderMeta | TFolderTemplateMeta | TPageMeta | TItemMeta | TItemTemplateMeta

// INTERFACES
// =======================================
export interface IAdminSetup {
    id: string
    description: string
    content?: string
    table?: string
    labelColumn?: string
    gqlGet?: string
    gqlInsert?: string
    gqlUpdate?: string
    gqlDelete?: string
    isMapping: boolean
    order: number
}
// In feite zou dit stand-alone moeten zijn, maar hier inheriten we van IQuestion voor gebruiksgemak
export interface IFormItem {
    id?: string | undefined
    type?: EFormItemTypes
    label?: string
    placeholder?: string
    value?: any
    metas?: any[]
    min?: number
    max?:  number
    step?: number
    required?: ERequiredTypes
    disabled?: boolean
    asText?: boolean

    default?: number | string | boolean
    xtraFormitemClass?: string

    field?: string
    valueOptions?: IValueOptions[]
    visibleValues?: number
    hasFilter?: boolean,
    allowClear?: boolean,
    isMultiSelect?: boolean,
    isMultiLevel?: boolean,    
    isOpenOnLoad?: boolean,
}

export interface IFormItemResponse {
    formitemId?: string
    formitemLabel?: string
    field: string
    value: any
}

export interface IFormItemMeta {
    question?: IFormItemMetaValue
    questionText: string
    label?: IFormItemMetaValue
    labelText: string
    subtitle?: IFormItemMetaValue
    subtitleText: string
    content?: IFormItemMetaValue
    icon?: IFormItemMetaValue
    iconText: string
    image?: IFormItemMetaValue
    imageText: string
    popupImage?: IFormItemMetaValue
    popupContent?: IFormItemMetaValue
    popupTitle?: IFormItemMetaValue
    popupSubtitle?: IFormItemMetaValue
}
export interface IFormItemMetaValue {
    value: JSX.Element | undefined
    replaceOnly: boolean
}

export interface IInputProps {
    formItem: IFormItem
    disabled?: boolean
    showLabel?: boolean
    replyValueOnLoad?: boolean
    
    xtraContainerClass?: string

    clickHandler?: any
    changeHandler?: (x: IFormItemResponse) => void
    blurHandler?: (x: IFormItemResponse) => void
    enterHandler?: any
    escapeHandler?: any
    optionsHandler?: (x: string) => Observable<IValueOptions[]>
}

export interface IValueOptions {
    id: number | string
    label: string
    parentId?: number | string
    isSeparator?: boolean
    isDisabled?: boolean
}

export interface IMeta {
    id: string
    type: EMetaTypes
    value: string
    active: boolean
    folderId: string

    translations: string[]
}

export interface IMetaHasLanguage {
    metaId: string
    languageId: number
    value: string
}

export interface IMetaType {
    id: string
}

export interface IMetaConnection {
    metaId: string
    order: number
    active: boolean

    meta?: IMeta
}

export interface IDropContainer {
    allowTypes: string[]
    dropHandler: TFuncVoid
    hoverHandler?: TFuncVoid
    clickHandler?: TFuncVoid
    greedy?: boolean
    xtraClasses?: string
    xtraStyles?: React.CSSProperties
}

export interface IDragItem {
    id?: string
    productId?: number
    index?: number
    origIndex?: number
    container: string
    name?: string
    type: TDragTypes
    isDropped?: boolean

    dropProps?: Partial<IDropContainer>
    dragItem?: Partial<IDragItem>
}

export interface IStringBuffer {
    type: string
    data: number[]
 }


 export interface IFileToUpload {
    id: number
    file: File
    valid: boolean
    errors: string[]
 }

 export interface IUploadedFile {
    numFiles: number
    files: { filename:string, size: string, type: string }[]
 }

// DEFAULTS
export const DMeta: Partial<IMeta> = {
    value: '',
    type: EMetaTypes.title,
    active: true,
    translations: []
}