import React, {PropsWithChildren} from "react"

const UiButton: React.FC<PropsWithChildren<{clickHandler: any , xtraClasses?: string[], disabled?: boolean}>> = (props : PropsWithChildren<{clickHandler: any, xtraClasses?: string[], disabled?: boolean}>) => {
    return (
        <button className={(props.xtraClasses || []).join(' ')} 
            onClick={props.clickHandler}
            disabled={props.disabled}
        >
           {props.children}
        </button>
    )
}

export default UiButton