import React from 'react'

import FolderCreator from './folderCreator'


// style={{width: '100%', minHeight: '500px', position: 'relative', margin: 'auto', overflowX: 'hidden', display: 'contents' }}
const App: React.FC = () => {
      document.title = 'Folder Creator - Ottevaere'

      return(
            <div className="dummycontainer CENTERFLEX" >
                  <FolderCreator></FolderCreator>
            </div>
      )
}

export default App
