import React, { useEffect, useState } from "react"
import _ from "lodash"

import DBService, { useService } from '@SERVICE/dbService'

import DropContainer from "./dropContainer"

import { IFolderItem, IFolderPage, IFolderPageTemplate, IProduct } from "@MODEL/folderModel"
import { AtLeast, IGlobalState } from "@MODEL/stateModel"
import { fetchQuery } from "@MODEL/dbModel"
import { IDragItem } from "@MODEL/uiModel"
import { Observable } from "rxjs"
import { TemplateComponentTypes } from "@COMP/product/templateTypes.type"

const templateComponentTypes: TemplateComponentTypes = new TemplateComponentTypes()

const PageContent: React.FC<{ page: IFolderPage }> = (props: { page: IFolderPage }) => {
    const dbState: IGlobalState = useService()[0]
    const [currItemTemplates, setItemTemplates] = useState<AtLeast<IFolderItem, 'rows' | 'cols' | 'descr' | 'order' >[]>([])
    const pageTemplate: IFolderPageTemplate = _.find(dbState.globalPageTemplates, x => x.id === props.page.pageTemplateId) || dbState.globalPageTemplates[0]

    const dropProduct = (item: IDragItem, container: string, index: number) => {
        if (item.container === container) return
        
        const isNew = item.container === '' || !item.id
            
        const tmpObj: Partial<IFolderItem> = {
            isWaiting: false,
            folderId: dbState.currFolder.id,
            pageId: props.page.id,
            itemTemplateId: _.find(dbState.globalItemTemplates, x => x.reactTemplate === item.type)?.id,
            order: index,
            startRow: Math.ceil(index/pageTemplate.cols),
            startCol: index % pageTemplate.cols,
        }

        let tmpObs: Observable<IFolderItem[]>

        if (isNew) {
            tmpObj.descr = item.name || '<no name>'
            tmpObj.productId = item.productId || 0
            tmpObj.reactTemplate = item.type

            tmpObs = DBService.dbRequest('POST', 'generic/folder_items', tmpObj)
        } else{
            tmpObs = DBService.dbRequest('PATCH', `generic/folder_items/${item.id}/id`, tmpObj)
        }

        tmpObs.subscribe((x: IFolderItem[]) => {
            // Huidige items updaten
            const currItems = dbState.currItems || []
            _.remove(currItems, y => x.map(z => z.id).includes(y.id))
            const tmpItems: IFolderItem[] = [...x, ...currItems]
            const newState: Partial<IGlobalState> = { currItems: tmpItems }

            // Kijken of het uit de wachtlijst kwam
            const currWachtItems = dbState.currWachtItems
            const itemsRemoved = _.remove(currWachtItems, y => x.map(z => z.id).includes(y.id))
            if (itemsRemoved.length) newState.currWachtItems = [...currWachtItems]

            // State updaten
            DBService.pushState(newState)  
        })

        console.log('item', item, container, index)
    }
    
    const setPageItems = (currPageItems: IFolderItem[]): void => {
        const totalPageItems = pageTemplate.rows * pageTemplate.cols
        // const numPageItems = totalPageItems - currPageItems.reduce((p, v) => p + (v.rows * v.cols - 1), 0)
        const usePageItems: AtLeast<IFolderItem, 'rows' | 'cols' | 'descr' | 'order' >[] = currPageItems
        const usedOrders: number[] = []
        usePageItems.forEach(x => {
            for (let col=0; col < x.cols; col++) {
                const colPos = x.order + col

                for (let row=0; row < x.rows; row++) {
                    usedOrders.push(colPos + (row * pageTemplate.cols))
                }
            }
        })

        console.log(usedOrders)
        
        // const usedOrders = usePageItems.map(x => x.order)

        for (let x=1; x <= totalPageItems; x++) {
            if (usedOrders.includes(x)) continue
            usePageItems.push({ cols: 1, rows: 1, descr: `item ${x}`, order: x })
        }

        console.log(totalPageItems, usePageItems)

        // for (let x=1; x <= totalPageItems; x++) {
        //     const tmpItem = _.find(currPageItems, y => y.order === x)
        //     if (tmpItem) {
        //         x += (tmpItem.rows * tmpItem.cols) - 1
        //         continue
        //     }  
        //     usePageItems.push({ cols: 1, rows: 1, descr: `item ${x}`, order: x })
        // }
        setItemTemplates([...usePageItems])
    }

    useEffect(() => {
        if (!(dbState.currItems?.length)) {
            setPageItems([])
            return
        }

        const currPageItems = _.filter(dbState.currItems, x => x.pageId === props.page.id)
        if (!currPageItems.length) {
            setPageItems(currPageItems)
            return
        }

        DBService.dbRequest('GET', 'query', undefined, { query: fetchQuery('products', { filter: `languageId=1 AND id IN (${currPageItems.map(x => x.productId)})` }) })
            .subscribe((x: IProduct[]) => {
                currPageItems.forEach(y => y.product = _.find(x, z => z.id === y.productId))
                setPageItems(currPageItems)
            })
    }, [dbState.currItems, props.page.pageTemplateId])

    const content: JSX.Element = (
        <div className="pageGrid" style={{ gridTemplateColumns: `repeat(${pageTemplate.cols}, 1fr)`, gridTemplateRows: `repeat(${pageTemplate.rows}, 1fr)` }}>
            {
                currItemTemplates.map((x, i) => {
                    const style: React.CSSProperties = { }
                    style.order = x.order
                    if (x.rows > 1 || x.cols > 1) {
                        if (x.startRow) {
                            style.gridRowStart = x.startRow
                            style.gridRowEnd = (x.startRow || 1) + x.rows
                        }
                        if (x.startCol) {
                            style.gridColumnStart = x.startCol
                            style.gridColumnEnd = (x.startCol || 1) + x.cols
                        }
                    }

                    const container = `Dropcontainer-Page-${props.page.id}-Item-${i}`
                    const TemplateComponent = templateComponentTypes[x.reactTemplate || 'newItem']

                    return <DropContainer
                                key={container} 
                                allowTypes={['product', 'generic']}
                                dropHandler={(item: IDragItem) => dropProduct(item, container, x.order)}
                                xtraClasses="dropZone FLEX-COLUMN SMALLGAP"
                                xtraStyles={style}
                            >
                                <TemplateComponent key={x.id} product={x.product} container={container} folderItem={x as IFolderItem}  addHandler={dropProduct} editProps={{ enableEdit: true, pageTemplate: pageTemplate }} />
                            {/* { !x.product 
                                ? <div>{x.descr}</div>
                                : <TemplateComponent key={x.id} product={x.product} container={container} folderItem={x as IFolderItem} editProps={{ enableEdit: true, pageTemplate: pageTemplate }} />
                            } */}
                                
                            </DropContainer>
                })
            }
        </div>
    )

    return <div className="pageContent">{content}</div>
}

export default PageContent
